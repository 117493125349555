@import 'src/design-system/styles/typography.variables';
@import 'src/design-system/styles/typography.mixins';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--shape-10);
}

.logo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 94px;
}

.logoImage {
  position: absolute;
  width: 215px;
  height: 43px;
}

.logoBackground,
.logoBackground::before,
.logoBackground::after {
  border: 25px solid var(--shape-30);
  border-radius: 50%;
}

.logoBackground {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 381px;
  height: 381px;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 263px;
    height: 263px;
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 147px;
    height: 147px;
  }
}

.title {
  @include font-style-h2($font-weight-regular);
  color: var(--text-10);
  margin: 0;
}

.subtitle {
  @include font-style-subtitle($font-weight-regular);
  color: var(--text-20);
  margin: 7px 0 0;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 61px 0 0;

  & li {
    @include font-style-small;

    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 16px;
    margin-top: 18px;
    color: var(--text-10);
    white-space: pre-line;

    &:first-child {
      margin-top: 0;
    }

    & > span {
      @include font-style-small($font-weight-bold);
    }

    &::before {
      @include font-style-h1($font-weight-regular);
      content: attr(data-id);
      grid-row: 1 / 3;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 7px;
      height: 39px;
      width: 39px;
      border-radius: 50%;
      color: var(--text-100);
      background: var(--primary-60);
    }
  }
}
