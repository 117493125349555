.inside {
  fill: var(--text-20);
}

.outside {
  fill: var(--text-30);
}

.wings {
  fill: var(--text-40);
}
