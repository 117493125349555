@import 'src/design-system/styles/typography.mixins';

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(000, 000, 000, .7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1005;
}

.modalHeader {
  position: relative;
  font-family: $font-family-default;
  @include font-style-subtitle($font-weight-bold);
  padding: 20px 48px 20px 24px;
  color: var(--text-10);
}

.modalContent {
  background-color: var(--shape-10);
  width: 100%;
  box-shadow: 0 4px 8px rgba(11, 31, 53, .04);
  border: 1px solid transparent;
  border-radius: 16px;
  color: var(--text-10);
}

.closeButton {
  background-color: transparent;
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  padding: 2px;
  border: none;
  color: var(--text-20);
}

.closeButton:hover {
  opacity: .7;
}

.closeButton:focus,
.closeButton:active {
  outline: 1px solid var(--text-link);
}

.modalBody {
  padding: 16px 24px;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
  border-top: 1px solid var(--shape-50);

  & button {
    margin-left: 8px;
  }
}
