@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.bet {
  background-color: var(--shape-20);
  border: 1px solid var(--shape-50);
  display: flex;
  gap: 8px;
  width: auto;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 8px;
}

.betPrice {
  font-weight: $font-weight-bold;
  color: var(--text-10);
}
