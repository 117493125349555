@mixin link-style {
  color: var(--text-link);

  &:hover {
    color: var(--text-primary);
    text-decoration: none;
  }
}

@mixin link-visited-style {
  &:visited {
    color: var(--text-visited-link);
  }
}
