body,
table {
  line-height: 17px;
}

/* stylelint-disable-next-line */
h1,
h2,
h3 {
  font-weight: 600;
}

/* stylelint-disable-next-line */
h4,
h5,
h6 {
  font-weight: 400;
}

h1 {
  font-size: 26px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
  line-height: 22px;
}

h4 {
  font-size: 16px;
  line-height: 19px;
}

h5 {
  font-size: 14px;
  line-height: 17px;
}

h6 {
  font-size: 12px;
  line-height: 14px;
}

input,
a,
button {
  &:focus,
  &:active {
    outline: none;
  }
}

// Убирает стрелочки для <input type="number" />
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}


// --

#app-root {
  height: 100%;
}

#modal-root {
  position: relative;
}

// --

td.ellipsis {
  position: relative;

  white-space: initial;
  overflow: initial;
  text-overflow: initial;

  &::before {
    content: '&nbsp;';
    visibility: hidden;
  }

  & > span,
  > a {
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// --

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin .5s infinite linear;
}
