@import 'src/design-system/styles/typography.mixins';

.container {
  width: 100%;
  gap: 16px;
}

.character,
.characterWithError {
  @include font-style-h4($font-weight-regular);

  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-10);
  background-color: var(--shape-50);
  border-radius: 4px;
}

.character {
  border: 1px solid var(--shape-50);
}

.characterSelected {
  border: 1px solid var(--text-30);
  outline: none;
}

.characterWithError {
  border: 1px solid var(--system-error);
}

.characterSelected:not(.characterFilled) {
  &::before {
    content: '|';
    color: var(--primary-90);
  }
}
