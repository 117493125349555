@import 'src/design-system/styles/typography.mixins';

.root {
  position: relative;
  display: grid;
  grid-template-columns: 97px 1fr;
  align-items: center;
  min-height: 64px;
  overflow: hidden;
  border-radius: 8px;
  background-color: var(--controls-segmented-bg);
}

.amount {
  position: relative;
  display: flex;
  justify-content: center;
  height: 145%;

  @include font-style-subtitle;
  font-weight: 400;
  color: var(--text-10);

  &::after {
    content: '';
    position: absolute;
    right: -7px;
    bottom: 0;
    width: 0;
    height: 100%;
    border-left: 6px var(--shape-10) dotted;
  }
}

.expireDate {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-left: 27px;

  @include font-style-super-small;
  color: var(--text-10);
}

.expireDatetime {
  @include font-style-small;
}

