@import 'src/design-system/styles/typography.mixins';

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin-top: 40px;
  width: 100%;
}

.code {
  padding: 25px;
  border-radius: 10px;
  background: var(--static-white);
}

.title {
  @include font-style-subtitle();
  margin: 0;
}
