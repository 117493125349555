@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/layout-variables';

.searchForm {
  position: relative;

  display: flex;
  align-items: center;

  width: 100%;
  height: $main-search-height;
  padding: 8px;

  border-radius: 4px;
}

.searchLine {
  @include font-style-default;
  background: transparent;
  white-space: nowrap;
  max-width: 100%;
  height: 100%;

  & input {
    outline: none;
    border: none;
    box-shadow: none;
  }
}

.closeButton {
  color: var(--text-10);
  background: none;
  padding: 0 16px;
  border: none;

  &:hover {
    opacity: .8;
  }
}

.dropdownContainer {
  box-shadow: 0 -1px var(--shape-50);
  height: 100%;
}
