@import '../../grid.variables';

.expansionButton {
  background-color: transparent;

  display: flex;
  justify-content: center;
  align-items: center;

  width: $grid-row-last-column-width;
  padding: 0;
  border: none;
  color: var(--text-link);

  cursor: pointer;

  & .expansionIcon {
    transition: transform .2s linear, -webkit-transform .2s linear;
    will-change: transform;

    &.rotated {
      transform: rotate(-180deg);
    }
  }
}
