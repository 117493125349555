@import 'src/design-system/styles/typography.mixins';
@import '../../../../../../grid.variables';

.stakeContainer {
  display: flex;
  justify-content: flex-end;
  padding: 0 4px;

  width: $grid-default-content-width;

  border: 1px solid var(--shape-50);
  border-top: none;

  & .stakeItem:last-child {
    border-right: 0;
  }
}

.smallContent {
  display: flex;
  justify-content: flex-end;

  & .stakeItem {
    border-left: 1px solid var(--shape-50);
    border-right: none;
  }
}

.stakeItem {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 124px;
  padding: 8px 0;
  border-right: 1px solid var(--shape-50);
}

.innerLineStake {
  width: 104px;
  border: none;

  &:global(.disabled) button:hover {
    background-color: var(--shape-10);
  }

  & button:global(.conflict) {
    &:hover,
    &:focus {
      background-color: var(--shape-10);
    }
  }

  & button {
    background-color: var(--shape-10);

    padding: 0 4px;
    border-radius: 4px;
  }

  & button:first-child {
    position: relative;
    margin-bottom: 13px;

    &::before {
      content: '';

      position: absolute;
      bottom: -7px;
      left: 0;
      right: -4px;

      height: 1px;
      background-color: var(--shape-50);
    }
  }

  & button:last-child {
    margin-top: 12px;
  }

  & button:first-of-type,
  button:last-of-type {
    padding-right: 4px;
    border: none;
  }
}

:global(.three-bets).innerLineStake {
  padding-right: 44px;

  & button:nth-child(2) {
    top: 0;
    bottom: 0;

    width: 35px;
    margin-left: 5px;
    border: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    &::before {
      content: '';

      position: absolute;
      left: -5px;
      top: 0;
      bottom: 0;

      width: 1px;
      background-color: var(--shape-50);
    }
  }
}
