
.background {
  fill: var(--shape-10);
}

.circle {
  fill: var(--shape-50);
}

.skeleton {
  fill: var(--text-30);
}

.button {
  fill: var(--primary-60);
}

.top {
  fill: var(--shape-30);
}

.star {
  fill: var(--controls-segmented-bg);
}

.roundStar {
  stroke: var(--controls-segmented-bg);
}
