
.container {
  display: inline-block;
  vertical-align: text-bottom;
  pointer-events: none;

  & > svg {
    display: block;
  }
}
