.root {
  position: relative;
  align-self: stretch;
}

.icon {
  position: relative;
  top: 2px;
  margin-right: 4px;
}

.dropdown ul {
  width: 256px;
  padding-top: 8px;

  & li:last-child {
    margin-top: 0;
  }
}
