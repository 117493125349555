@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.pledgeStakeDropdown {
  margin-top: 4px;
}

.inputContainer {
  display: grid;
  grid-template-columns: 1fr auto;

  min-height: 32px;
  margin-top: 4px;
  border-radius: 4px;

  &:hover {
    & .input,
    & .units {
      border-color: var(--text-40);
    }
  }
}

.input:focus {
  border-color: var(--text-link) !important;

  & ~ .units {
    border-color: var(--text-link);
  }
}

.units {
  border-right: 1px solid var(--shape-40);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.disabled {
  pointer-events: none;

  & .input,
  & .units {
    background: var(--shape-10);
  }

  & .units path {
    fill: var(--text-30);
  }

  & .input {
    color: var(--text-10);

    &::placeholder {
      color: var(--text-30);
    }
  }
}

.error {
  & .input,
  & .units {
    border-color: var(--system-error) !important;
  }
}
