.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 24px;
  gap: 24px;
}

.control {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
}

.title {
  margin: 0;
}
