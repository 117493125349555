@mixin systemLight {
  --system-success-light: #e6f7e6;
  --system-success: #4bb462;
  --system-error-light: #f9e7e7;
  --system-error: #c75f57;
  --system-warning-light: #fff5e5;
  --system-warning: #ffc11e;
}

@mixin systemDark {
  --system-success-light: #0e1f0e;
  --system-success: #609c6d;
  --system-error-light: #210f0f;
  --system-error: #ad635e;
  --system-warning-light: #27200d;
  --system-warning: #e5ba49;
}
