.inputContainer {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto auto;

  min-height: 32px;
  border-radius: 4px;

  &:hover {
    & .input,
    .units {
      border-color: var(--text-40);
    }
  }

  &.error {
    & .input,
    & .units {
      border-color: var(--system-error) !important;
    }
  }
}

.disabled {
  pointer-events: none;

  & .input,
  & .input > input,
  & .units {
    background: var(--shape-20);
  }

  & .units path {
    fill: var(--text-40);
  }

  & .input,
  & .input > input {
    color: var(--text-40);

    &::placeholder {
      color: var(--text-40);
    }
  }
}
