@import 'src/design-system/styles/typography.mixins';

.modalRequestError {
  margin-top: 30px;
  border: 1px solid var(--system-error);
  border-radius: 4px;
  padding: 8px;

  & .modalErrorText {
    @include font-style-small;
    color: var(--system-error);
    margin: 0;

    & a {
      color: var(--text-link);
      text-decoration: none;
    }
  }
}
