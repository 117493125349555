@import 'src/design-system/styles/typography.mixins';

.lineButtons {
  display: flex;
  flex-direction: column;

  width: 36px;

  & .betsCount {
    @include font-style-extra-small($font-weight-bold);
    @include one-line-ellipsis;
    font-size: 11px;
    color: var(--text-link);

    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    border-bottom: 1px solid var(--shape-50);
  }

  & > button {
    flex: 1;
    border-bottom: 1px solid var(--shape-50);
  }
}

.singleEventButtons {
  border-left: 1px solid var(--shape-50);
  z-index: 2;
}
