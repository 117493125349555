@mixin gridLight {
  --grid-bet-bg: #f9f8f8;
  --grid-increase-bg: #c9e9d0;
  --grid-decrease-bg: #efcfcd;
  --grid-match-score-change-bg: #eff4dc;
  --grid-static-bet-bg: #f9f8f8;
  --grid-static-increase-bg: #c9e9d0;
  --grid-static-decrease-bg: #efcfcd;
}

@mixin gridDark {
  --grid-bet-bg: #232323;
  --grid-increase-bg: #324436;
  --grid-decrease-bg: #493331;
  --grid-match-score-change-bg: #40412c;
  --grid-static-bet-bg: #f9f8f8;
  --grid-static-increase-bg: #c9e9d0;
  --grid-static-decrease-bg: #efcfcd;
}
