@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.root {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 5px;
  padding: 20px 24px;
  width: 100%;
  background: var(--shape-10);
}

.rootLink {
  &:hover {
    background: var(--shape-40);

    & .title {
      color: var(--text-primary);
    }
  }
}

.title {
  @include font-style-default($font-weight-bold);
  margin: 0;
  color: var(--text-10);
}

.body {
  @include font-style-small;
  color: var(--text-20);
}

.header {
  display: flex;
  justify-content: space-between;
}

.date {
  @include font-style-tiny;
  color: var(--text-20);
}

.status {
  @include font-style-small($font-weight-bold);
  color: var(--text-30);
}

.statusSuccess {
  color: var(--system-success);
}

