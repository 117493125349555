@import 'src/design-system/styles/typography.mixins';

.content {
  text-align: center;
}

.text {
  @include font-style-default;
  margin-bottom: 0;
  color: var(--text-20);
}
