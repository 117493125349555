@import 'src/design-system/styles/layout-variables';
@import 'src/design-system/styles/layout-mixins';

.scrollbar {
  @include contentWithPaddings;
  position: relative;
  box-sizing: content-box;
  height: $height-without-header;
  margin: 0 auto;

  & > :global(.simplebar-track) {
    right: calc((100% - #{$content-width} - #{$left-menu-width} + #{$sidebar-width}) / 2);
  }

  & :global(.simplebar-content) {
    display: grid;
    grid-template-rows: 1fr max-content;
    grid-template-columns: 100%;
    min-height: 100%;
  }
}

.scrollbarSSR {
  display: none;
}

.layoutContainer {
  position: relative;
  height: 0;
  width: $screen-size-sm;
  min-width: $screen-size-sm;
  margin: 0 auto;
  justify-self: center;
}

.layoutContainerSSR {
  display: none;
}

.leftMenu {
  position: absolute;
  left: 0;
  z-index: 1;
  overflow: hidden;
  max-height: calc(100vh - $main-header-height - $left-menu-indent-top - $left-menu-indent-bottom);
}

.leftMenuNew {
  top: $content-indent-top;
  width: $left-menu-width;
  padding: $left-menu-padding-top 0 $left-menu-padding-bottom $left-menu-padding-left;
  border-radius: 8px;
  background-color: var(--shape-10);
}
