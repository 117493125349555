@import 'src/design-system/styles/typography.mixins';

.error {
  @include font-style-small;

  margin-bottom: 0;
  padding-bottom: 8px;
  text-align: center;

  color: var(--system-error);
  background-color: var(--shape-10);
}

.button {
  width: 100%;
  border-radius: 0;
}
