@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/layout-variables';
@import '../../../../grid.variables';

$content-without-bets-width: $grid-default-content-width + $grid-row-last-column-width;

.contentWithoutBets {
  @include font-style-super-small;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $content-without-bets-width;
  color: var(--text-30);
  text-align: center;
  min-height: 80px;
  border-left: 1px solid var(--shape-50);
  border-bottom: 1px solid var(--shape-50);
}

.contentWithMainBets {
  @include font-style-super-small;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: $grid-default-content-width;
  min-height: 80px;
  border: 1px solid var(--shape-50);
  border-top: none;
  background: transparent;

  &:hover {
    & .expansionText {
      color: var(--text-primary);
    }
  }
}

.expansion {
  display: flex;
  gap: 5px;
}

.expansionText {
  color: var(--text-link);
  margin: 0;
}

.rightCellHeadToHead {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 4px;
  border: 1px solid var(--shape-50);
  border-top: none;
}
