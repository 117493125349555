@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.header {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;

  width: 100%;
  padding: 0;

  border: none;
  background: transparent;

  &:hover {
    & .label,
    & .progress {
      opacity: .8;
    }
  }
}

.statusContainer {
  display: flex;
  align-items: center;

  & .errorFilled {
    color: var(--system-error);
  }

  & .successFilled {
    color: var(--system-success);
  }
}

.label {
  @include font-style-small;
  margin: 0 10px 0 8px;
  color: var(--text-10);
}

.progress {
  @include font-style-small($font-weight-bold);
  margin: 0;
  color: var(--text-10);
}

.toggle {
  justify-self: end;
  color: var(--text-30);
}

.closeBigStakeBarButton {
  display: flex;
  justify-self: end;

  padding: 3px;
  margin-right: -3px;

  background: transparent;
  border: none;

  &:hover,
  &:focus {
    & path {
      opacity: .8;
    }
  }
}
