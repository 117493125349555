.background {
  fill: var(--shape-00);
}

.number {
  fill: var(--text-30);
}

.numberBorder {
  stroke: var(--shape-50);
}

.skeleton {
  fill: var(--shape-50);
}

.ticket {
  fill: var(--shape-10);
}
