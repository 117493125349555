.indicator {
  & path:last-child {
    animation: blink 1.5s ease-in-out alternate infinite;
  }
}

@keyframes blink {
  from {
    opacity: 1;
  }

  to {
    opacity: .35;
  }
}
