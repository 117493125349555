@import 'src/design-system/styles/layout-variables';

@mixin clear-link($color...) {
  &:link,
  &:hover,
  &:visited {
    @if length($color) == 0 {
      $color: #337ab7;
    }

    color: $color;
    text-decoration: none;
  }
}

.modal > div:first-child {
  position: absolute;
  top: 15%;
}

.linksList {
  display: grid;
  width: 300px;
  flex-grow: 1;
  flex-shrink: 1;
  grid-template-columns: repeat(auto-fit, minmax(0, max-content));
  justify-content: flex-end;
  gap: 30px;
  margin: 0;
  padding: 0;

  & .link {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    cursor: pointer;

    @include clear-link(var(--text-10));
  }
}
