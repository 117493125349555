.textClassName {
  fill: var(--text-10);
}

.circleClassName {
  fill: var(--static-secondary);
}

.regionClassName {
  fill: var(--static-primary);
}
