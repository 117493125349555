@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.container {
  display: flex;
  align-items: center;
  color: var(--text-20);
}

.text {
  @include font-style-tiny;

  margin: 0;
}

.amount {
  @include font-style-tiny;
  flex-shrink: 0;
  color: inherit;
}

.error {
  & > .text {
    @include font-style-tiny($font-weight-medium);
    color: var(--system-error);
  }

  & > .amount {
    color: var(--system-error);
  }
}

.button {
  flex-direction: column;

  border: none;
  padding: 0;

  background: none;

  & > .amount {
    color: var(--text-link);
  }

  &::after {
    content: '';
    display: block;

    width: 100%;
    height: 1px;
    margin-top: -1px;

    background-color: var(--text-link);
  }

  &:hover,
  &:focus {
    opacity: .8;
  }

  &[disabled] {
    pointer-events: none;

    & > .amount {
      color: var(--text-30);
    }

    &::after {
      background-color: var(--text-30);
    }
  }
}
