.background {
  fill: var(--shape-00);
}

.item {
  fill: var(--shape-10);
}

.border {
  stroke: var(--shape-00);
}

.skeleton {
  fill: var(--shape-50);
}

.checkMark {
  fill: var(--text-40);
}
