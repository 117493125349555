@import 'src/design-system/styles/typography.mixins';

.infoText {
  @include font-style-tiny;
  color: var(--text-20);
  padding: 16px 24px;
  text-align: center;
}

.offerLink {
  @include font-style-tiny;
  cursor: pointer;
}
