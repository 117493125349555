.selectContainer {
  padding: 2px 8px;
}

.sportsSelect {
  display: inline-block;

  & :global(.dropdown__control) {
    min-width: 160px;
    border: none;
    padding: 8px !important;

    &:hover {
      border-color: transparent;
    }

    &:global(.dropdown__control--is-focused) {
      border: none;
      box-shadow: none;
    }

    & :global(.dropdown__value-container) {
      padding: 0;
    }
  }

  & :global(.dropdown__menu) {
    width: 215px;
    margin-top: 3px;

    overflow: hidden;
  }

  & :global(.dropdown__indicator) {
    padding: 0;
  }
}
