@import 'src/design-system/styles/typography.mixins';

.container {
  position: relative;
  padding: 8px 12px;

  background: var(--shape-10);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.input {
  margin-top: 8px;
}

.submission {
  opacity: 1;
}

.disabledTextBar > span {
  color: var(--text-30);
}

.rankHeader {
  @include font-style-tiny;

  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: column;

  color: var(--text-10);
}

.toggleContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 10px;
}

.tooltipIcon {
  margin-left: 4px;

  & path {
    fill: var(--text-30);
  }
}

.rankSelect {
  grid-row: 1 / 3;
  height: 32px;
  align-self: center;
}

.dropdown {
  & :global(.dropdown__menu) {
    margin: 0;
  }

  & :global(.dropdown__control) {
    min-width: 0;
  }
}
