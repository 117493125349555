@import 'src/design-system/styles/typography.mixins';

.spinner {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  width: 100%;
}

.loggedInStakeContainer {
  padding: 0 30px;
  margin-top: 42px;
  text-align: center;
}

.emptyStakeText {
  @include font-style-small;
  color: var(--text-40);
  padding-top: 5px;
  margin: 0;
}
