@import 'src/design-system/styles/typography.mixins';

.list {
  @include list-styles-reset;
  border-radius: 8px;
  overflow: hidden;
}

.item {
  display: flex;
  flex-direction: column;
  background-color: var(--shape-50);
  box-shadow: 0 0 0 1px var(--shape-00);
}

.iconContainer {
  padding: 1px;
  border-radius: 3px;
  background-color: var(--shape-20);
}

.link {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 8px 8px 12px;
  color: var(--text-10);
  @include font-style-tiny($font-weight-medium);
}

.linkSelected,
.linkSelected:hover,
.linkSelected:focus-visible {
  color: var(--text-100);
  background-color: var(--primary-60);
}
