.content {
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > :first-child {
    margin-top: 0;
  }
}

.noData {
  text-align: center;
}

.modalBodyScroll {
  height: 500px;
  padding: 0 24px;
}
