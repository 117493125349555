@import 'src/design-system/styles/typography.mixins';

.container {
  position: relative;

  margin-top: 6px;
  padding: 8px;

  background-color: var(--shape-10);
  border: 1px solid var(--shape-50);

  border-radius: 8px;
}

.stakeNameContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  width: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.badges {
  display: flex;
}

.singleBadges {
  display: flex;
  gap: 3px;
  margin-bottom: 6px;
}

.disabledEntry {
  pointer-events: none;
  opacity: .7;
}

.notConflictedEntry {
  pointer-events: none;
  opacity: .5;
}

.footerWrapper {
  position: relative;
}

