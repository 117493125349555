@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.container {
  min-height: 273px;
}

.enterCode,
.forgotPassword {
  @include font-style-small;

  display: block;
  padding: 8px 0 0 0;
  margin-left: auto;

  background: transparent;
  color: var(--text-link);

  border: none;
  cursor: pointer;

  &:hover,
  &:focus {
    color: var(--text-primary);
  }
}

.inputList {
  list-style: none;
  margin: 0 auto;
  padding: 0 0 8px 0;

  & li:first-of-type {
    margin-bottom: 1px;
  }
}

.passwordInput {
  box-sizing: border-box;
  max-width: 500px;

  & input {
    box-sizing: border-box;
  }
}

.errorMessage {
  @include font-style-small;
  display: flex;
  justify-content: center;
  height: 0;
  color: transparent;
  transform: skew(-50deg);
  user-select: none;
}

.showErrorMessage {
  color: var(--system-error);
  transform: skew(0deg);
  transition: all .3s ease .55s; // delay 0.55 sek - start transition after shake animation
  user-select: unset;
}
