@import 'src/design-system/styles/typography.variables';
@import 'src/design-system/styles/typography.mixins';

.matchDetailsContainer {
  position: relative;
  display: flex;
  justify-content: space-between;

  &:hover li {
    color: var(--text-primary);
  }
}

.matchTitle {
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.matchScores {
  background: linear-gradient(270deg, var(--shape-10) 90%, transparent 100%);
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  padding-left: 32px;
  margin: 0;
}

.singleEvent {
  @include font-style-tiny($font-weight-bold);
  @include one-line-ellipsis;
  display: block;
  max-width: 220px;
  color: var(--text-10);
  padding: 12px 0;
}

.matchInformation {
  display: flex;
  align-items: center;
  padding-top: 4px;
}

.liveIndicatorIcon {
  padding-right: 4px;
  color: var(--system-error);
}
