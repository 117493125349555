@import 'src/design-system/styles/typography.mixins';
@import '../../../../grid.variables';

.root {
  @include font-style-super-small;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: $grid-default-content-width;

  height: 100%;
  padding: 0 20px;
  color: var(--text-20);
  border-left: 1px solid var(--shape-50);
  border-bottom: 1px solid var(--shape-50);
}

.title {
  color: var(--text-10);
  font-weight: bold;
}
