@import 'src/design-system/styles/typography.variables';
@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/themes/palettes/sportColors';

.tournamentName {
  position: sticky;
  top: 40px;
  display: flex;
  align-items: center;
  min-height: 32px;
  padding: 0 12px;
  background-color: var(--shape-10);
  border-bottom: 1px solid var(--shape-50);
  z-index: 10;

  & p,
  .stagesBreadcrumbs {
    display: grid;
    grid-template-columns: repeat(6, fit-content(100%));
    align-items: center;
  }

  & p {
    margin: 0;
    padding-right: 6px;
  }

  & .stagesBreadcrumbs button {
    padding-left: 4px;
  }

  & button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.link {
  @include font-style-small($font-weight-bold);
  color: var(--text-10);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@each $sportKey, $color in $sportColors {
  .#{$sportKey} {
    border-top: 2px solid $color;
  }
}
