@import 'src/design-system/styles/typography.variables';
@import 'src/design-system/styles/typography.mixins';

.spinnerPosition {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--shape-10);
}

.errorTemplate {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
  text-align: center;
}

.errorDetails {
  width: 314px;
  text-align: center;
  padding: 0 2px;
  margin: 0 auto;

  & h3 {
    color: var(--text-10);
  }

  & p {
    @include font-style-default;
    color: var(--text-20);
    padding-top: 6px;

    & a {
      white-space: nowrap;
      border-bottom: 1px solid;
    }
  }
}

.logoNotFound {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}
