@import 'src/design-system/styles/typography.mixins';

$input-width: 220px; // используется в FormControl

.root {
  position: relative;

  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;

  height: 40px;
  width: inherit;
  min-width: $input-width;
  max-width: 400px;

  &.disabled {
    pointer-events: none;
    color: var(--text-30);
    background-color: var(--shape-20);

    & input,
    .units {
      border-color: var(--input-default-border);
      color: var(--text-30);
      background-color: var(--shape-20);
    }

    &:hover {
      & input,
      & .units {
        border-color: var(--input-default-border);
      }
    }

    & .iconButton,
    .hint {
      color: var(--input-default-border);
    }

    & > * { // * - left icon, right icon, units, hint, input
      cursor: not-allowed;
    }

    & input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 50px var(--shape-20) inset;
      -webkit-text-fill-color: var(--text-30);
    }
  }

  &:hover {
    & input,
    .units {
      border-color: var(--text-20);
    }
  }


  & input {
    outline: none;
    height: inherit;
    width: 100%;
    margin: 0;
    padding: 0 16px;
    border: 1px solid var(--input-default-border);
    border-radius: 4px;
    background-color: var(--shape-10);
    color: var(--text-10);
    caret-color: var(--text-10);
    filter: none;

    &:focus {
      border-color: var(--text-link);

      & + .units {
        border-color: var(--text-link);
      }
    }

    &::placeholder {
      color: var(--text-30);
    }
  }

  & input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px var(--shape-10) inset;
    -webkit-text-fill-color: var(--text-10);

    &:focus {
      -webkit-box-shadow: 0 0 0 50px var(--shape-10) inset;
      -webkit-text-fill-color: var(--text-10);
    }

    &::placeholder {
      -webkit-text-fill-color: var(--text-30);
    }
  }

  & input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
}

.iconButton {
  display: flex;
  border: none;
  background: none;
  padding: 2px;
  outline: none;
  position: absolute;
  color: var(--text-30);

  &:focus {
    outline: 1px solid var(--text-link);
  }
}

.iconButtonNoPointers {
  pointer-events: none;
}

.iconLeft {
  left: 14px;
}

.iconRight {
  right: 14px;
}

.error {
  & input,
  .units {
    border-color: var(--system-error);
  }

  &:hover {
    & input,
    .units {
      border-color: var(--system-error);
    }
  }

  & input:focus,
  & input:focus + .units {
    border-color: var(--system-error);
  }
}

.hasUnits input {
  padding-right: 0;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}


.hint {
  position: absolute;
  right: 39px;
  padding: 4px;
  color: var(--text-40);
}

.units {
  font-size: 20px;
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 6px;
  padding-right: 16px;

  color: var(--text-40);
  background: var(--shape-10);
  border: 1px solid var(--input-default-border);
  border-left: none;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.smallInput {
  @include font-style-small;

  height: 28px;

  & input {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  &.hasIconLeft input {
    padding-left: 42px;
  }

  &.hasIconRight input {
    padding-right: 42px;
  }

  &.hasHint input {
    padding-right: 57px;

    & .hint {
      right: 33px;
    }

    & .iconRight {
      right: 11px;
    }
  }

  &.hasUnits .units {
    height: 28px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.mediumInput {
  @include font-style-default;

  height: 40px;

  & input {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &.hasIconLeft input {
    padding-left: 48px;
  }

  &.hasIconRight input {
    padding-right: 48px;
  }

  &.hasHint input {
    padding-right: 64px;

    & .iconRight {
      right: 11px;
    }
  }

  &.hasUnits .units {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.largeInput {
  @include font-style-subtitle;

  font-weight: $font-weight-regular;
  height: 52px;

  & input {
    padding-top: 14px;
    padding-bottom: 14px;
  }

  &.hasIconLeft input {
    padding-left: 48px;
  }

  &.hasIconRight input {
    padding-right: 48px;
  }

  &.hasHint input {
    padding-right: 64px;

    & .iconRight {
      right: 11px;
    }
  }

  &.hasUnits .units {
    height: 52px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

