@import 'src/design-system/styles/typography.mixins';

.dropdown {
  & :global(.dropdown__control) {
    min-width: 220px;
    border: 1px solid var(--input-default-border);
    cursor: pointer;
    padding: 5px 8px;
    background: var(--shape-10);
    transition: none;

    &:hover {
      border-color: var(--text-20);
    }
  }

  & :global(.dropdown__control--is-focused) {
    border: 1px solid var(--text-link);
    box-shadow: none;

    &:hover {
      border-color: var(--text-link);
    }

    & :global(.dropdown__input-container) {
      color: var(--text-10);
    }
  }

  & :global(.dropdown__control--is-disabled) {
    background-color: var(--shape-20);
    border: 1px solid var(--input-default-border);
    cursor: not-allowed;

    & :global(.dropdown__single-value) {
      color: var(--text-30);
    }

    &:hover {
      border-color: var(--input-default-border);
    }

    & :global(.dropdown__value-container .dropdown__placeholder) {
      color: var(--text-30);
    }
  }

  & :global(.dropdown__value-container) {
    @include font-style-small;
    padding-right: 4px;
  }

  & :global(.dropdown__single-value) {
    margin-left: 0;
    color: var(--text-10);
  }

  & :global(.dropdown__placeholder) {
    margin-left: 0;
    color: var(--text-30);
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & :global(.dropdown__dropdown-indicator) {
    & svg {
      fill: var(--text-30);
    }
  }

  & :global(.dropdown__input) {
    opacity: 1 !important;
  }

  &.error {
    & :global(.dropdown__control) {
      border: 1px solid var(--system-error);
    }

    & :global(.dropdown__control--is-disabled) {
      border: 1px solid var(--input-default-border);
    }
  }

  &.large {
    & :global(.dropdown__control) {
      padding: 13px 12px 13px 16px;
    }

    & :global(.dropdown__value-container) {
      @include font-style-subtitle;
    }

    // Не работает с порталами
    & :global(.dropdown__option) {
      @include font-style-subtitle;
      padding: 13px 16px;
    }
  }

  &.medium {
    & :global(.dropdown__control) {
      padding: 7px 8px 7px 12px;
    }

    & :global(.dropdown__value-container) {
      @include font-style-default;
    }

    // Не работает с порталами
    & :global(.dropdown__option) {
      @include font-style-default;
      padding: 8px 12px;
    }
  }

  &.small {
    & :global(.dropdown__control) {
      padding: 3px 8px;
    }

    // Не работает с порталами
    & :global(.dropdown__option) {
      padding: 4px 8px;
    }
  }
}

:global(div.dropdown__menu) {
  border-radius: 4px;
  border: 1px solid var(--shape-50);
  box-shadow: var(--shadow-2);
  background: var(--shape-10);

  & :global(.dropdown__menu-list) {
    padding: 0;
    overflow: hidden;
  }

  & :global(.dropdown__menu-notice--no-options) {
    @include font-style-tiny;
    padding: 24px 16px;
  }

  & :global(.dropdown__option) {
    @include font-style-small;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: var(--text-10);
    padding: 6px 8px;
    cursor: pointer;

    &:active {
      background-color: var(--shape-50);
    }
  }

  & :global(.dropdown__option--is-focused) {
    background-color: var(--shape-50);
  }

  & :global(.dropdown__option--is-disabled) {
    color: var(--text-30);
    pointer-events: none;
  }

  & :global(.dropdown__option--is-selected) {
    background-color: transparent;
    font-weight: bold !important;

    &:active {
      background-color: transparent;
    }
  }
}

.menuListCommon {
  overflow-y: hidden !important;
}

.scrollContainer {
  & :global(.simplebar-content-wrapper .simplebar-content) {
    min-height: auto;
  }
}
