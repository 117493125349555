@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';


.dropdown {
  display: flex;
  height: 100%;

  & :global(.dropdown__value-container) {
    @include font-style-default;
    padding: 0;
  }

  & :global(.dropdown__single-value),
  :global(.dropdown__placeholder) {
    font-size: 12px;
    line-height: 24px;
    position: static;
    top: 0;
    max-width: 100%;
    margin-right: 0;

    color: var(--text-20);
    transform: none;
  }

  & :global(.dropdown__input) {
    opacity: 0;
  }

  & :global(.dropdown__indicator) {
    & svg {
      fill: var(--text-20);
    }
  }

  & :global(.dropdown__control) {
    height: 100%;
    min-width: 0;
    min-height: 0;

    border: none;
    border-radius: 0;
    transition: none;
    padding: 0;

    &:hover {
      border-color: transparent;
    }

    &:global(.dropdown__control--is-focused) {
      border: none;
      box-shadow: none;
    }
  }

  & :global(.dropdown__placeholder) {
    white-space: nowrap;
  }

  & :global(.dropdown__control--is-disabled) {
    pointer-events: none;

    & :global(.dropdown__single-value) {
      color: var(--text-30);
    }

    & :global(.dropdown__indicator) svg {
      fill: var(--text-30);
    }
  }
}

:global(.dropdown__menu-portal) {
  z-index: 100 !important;
}

.portal {
  & :global(.dropdown__menu) {
    position: relative;
    top: 10px;
    left: -20px;
    margin: 1px 0;
    width: 260px;
    min-width: 109px;

    border: none;
    border-radius: 4px;
    background-color: var(--shape-10);
    box-shadow: var(--shadow-4);

    &::before {
      position: absolute;
      display: block;
      content: '';
      top: -10px;
      left: 19px;
      width: 18px;
      height: 9px;
      background-color: var(--shape-10);
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='9' viewBox='0 0 18 9'%3E%3Cpath fill='currentColor' d='M7.6 1.4 0 9h18l-7.6-7.6a2 2 0 0 0-2.8 0Z'/%3E%3C/svg%3E");
    }
  }

  & :global(.dropdown__menu-list) {
    padding: 0;
  }

  & :global(.dropdown__menu) :global(.dropdown__option) {
    display: flex;
    align-items: center;
    min-height: 36px;
  }

  & :global(.dropdown__option--is-selected) {
    background-color: transparent;
    font-weight: bold;

    &:active {
      background-color: transparent;
    }
  }

  & :global(.dropdown__option--is-disabled) {
    cursor: default;
    color: var(--text-30);
  }
}

.sportInfo {
  @include font-style-tiny($font-weight-bold);

  padding: 10px 0 5px 9px;
}

.sportIcon {
  margin-right: 7px;
}

.sportIconSingleValue {
  margin-right: 5px;
}

.option {
  @include one-line-ellipsis;
}

.topMatch {
  @include font-style-small($font-weight-bold);
}

.sportType {
  text-transform: uppercase;
}
