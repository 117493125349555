@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.container {
  padding: 6px 8px 0;
  min-height: 60px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
}

.title {
  @include font-style-default($font-weight-bold);

  padding: 0;
  margin: 0;
  color: var(--text-10);
}

.notFound {
  @include font-style-tiny($font-weight-medium);

  color: var(--text-20);
}

.actions {
  & button {
    background: transparent;
    border: none;
  }

  & button[disabled] {
    cursor: not-allowed;

    & svg path {
      fill: var(--text-10);
    }
  }
}
