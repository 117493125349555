@import 'src/design-system/styles/typography.mixins';

.container {
  & :global(.sidebar-panel-header) {
    height: auto;
  }
}

.betSlipContent {
  position: relative;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: var(--text-10);
  opacity: .3;

  z-index: 200;
}

.emptyBetSlip {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 119px;
  margin: 0;

  & span {
    @include visuallyHidden;
  }
}

.footer {
  background-color: var(--shape-00);
}

.bigStake {
  @include font-style-small;
  color: var(--text-10);

  text-align: center;

  margin: 0;
  padding: 8px;

  background-color: var(--shape-10);

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.scrollbarBetSlip {
  height: auto;
}
