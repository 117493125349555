@import 'src/design-system/styles/typography.mixins';

.container {
  width: 208px;
  margin: 0 auto;
}

.fieldInfo {
  @include font-style-tiny;
}

.error {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);

  color: var(--system-error);
  text-align: center;
  margin-top: 20px;
}

.fieldInfoSendCode {
  @include font-style-subtitle($font-weight: $font-weight-regular);

  color: var(--text-10);
  text-align: center;
  margin-bottom: 15px;
}

.confirmCodeInput {
  width: 208px;
  margin: 0 auto;
}
