@import 'src/design-system/styles/layout-variables';

.container {
  display: flex;
  flex-direction: column;
  padding: $content-indent;
  gap: 24px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.additionalInfo {
  margin-top: 24px;
}

.divider {
  margin-bottom: 24px;
}
