@import 'src/design-system/styles/typography.mixins';

.input {
  @include font-style-small;

  width: 100%;
  height: 100%;
  min-width: initial;
  max-width: initial;
  padding: 6px 0 6px 8px;

  color: var(--text-10);
  border: 1px solid var(--shape-40);
  border-right: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  & > input {
    padding: 0;
    border: none;

    &::placeholder {
      color: var(--text-30);
    }

    &:disabled {
      color: var(--text-40);
      background: var(--shape-20);
    }
  }

  &.disabled > input {
    box-shadow: none;
  }
}

.units {
  display: flex;
  align-items: center;
  padding: 0 13px 0 8px;
  color: var(--text-30);

  border-top: 1px solid var(--shape-40);
  border-bottom: 1px solid var(--shape-40);
}

.withoutChainDropdown {
  border-right: 1px solid var(--shape-40);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.focus {
  border-color: var(--text-link) !important;

  & ~ .units {
    border-color: var(--text-link) !important;
  }
}
