.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;

  margin-top: 8px;
  padding: 8px;

  border: 1px solid var(--shape-50);
  border-radius: 8px;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  width: 100%;
}

.main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  width: 100%;
}

.error {
  margin-top: 3px;
}
