.root {
  position: fixed;
  left: 50%;
  bottom: 24px;
  transform: translateX(-50%);
  z-index: 1005;
  width: 536px;
  max-height: 192px;
  border-radius: 4px;
  background-color: var(--shape-10);
  box-shadow: var(--shadow-4);
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
  border-top: 1px solid var(--shape-50);
}

.text {
  padding: 24px;
}
