@import 'src/design-system/styles/typography.variables';
@import 'src/design-system/styles/typography.mixins';

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  width: 100%;
}

.entryName {
  @include font-style-small($font-weight-bold);
  line-height: $line-height-extra-small;

  margin: 0;
  text-align: left;

  color: var(--text-10);
}

.stakeName {
  @include font-style-small($font-weight-medium);
  line-height: $line-height-extra-small;

  margin: 0 2px 0 0;

  color: var(--text-20);
}

.stakeName,
.entryName {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.matchName {
  @include font-style-small($font-weight-medium);

  margin-bottom: 6px;

  color: var(--text-30);
  overflow: hidden;
  text-overflow: ellipsis;
}

.nameError {
  color: var(--text-30);
}

.errorMessage {
  @include font-style-tiny($font-weight-medium);
  color: var(--system-error);
}

.removeEntryButton {
  display: flex;
  background: none;
  border: none;
  padding: 0;
  margin-left: 12px;
  color: var(--text-10);

  &:disabled {
    display: none;
  }

  &:hover,
  &:focus {
    opacity: .8;
  }
}

.datetime {
  @include font-style-tiny($font-weight-bold);

  padding: 0 4px;

  color: var(--text-20);
  background: var(--shape-30);
  border-radius: 3px;
  white-space: nowrap;
}
