@import 'src/design-system/styles/typography.mixins';

.footerCancelButton {
  min-width: 88px;
}

@keyframes success-icon {
  0% {
    transform: scale(4);
    opacity: 0;
  }

  40% {
    opacity: 1;
  }

  100% {
    transform: scale(1);
  }
}

.iconSuccess {
  color: var(--static-white);
  margin: auto 0;
  padding: 4px;
  animation: success-icon .32s cubic-bezier(.42, 0, 1, 1);
}

.container {
  padding: 16px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.initialContainer {
  padding-bottom: 8px;
  gap: 17px;
}
