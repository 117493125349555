.container {
  padding: 16px 24px 8px;
  display: flex;
  flex-direction: column;
  gap: 17px;
}

@keyframes success-icon {
  0% {
    transform: scale(4);
    opacity: 0;
  }

  40% {
    opacity: 1;
  }

  100% {
    transform: scale(1);
  }
}

.iconSuccess {
  color: var(--static-white);
  margin: auto 0;
  animation: success-icon .32s cubic-bezier(.42, 0, 1, 1);
}

.footerEnterButton {
  min-height: 40px;
}
