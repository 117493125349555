@import 'src/design-system/styles/typography.variables';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 32px 0;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  font-weight: $font-weight-bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: var(--text-10);
}

.description {
  font-weight: $font-weight-regular;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--text-10);
}
