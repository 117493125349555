@import 'src/design-system/styles/typography.mixins';

.repeatSendCode {
  padding: 0;

  color: var(--text-link);
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    color: var(--text-primary);
  }

  &:active {
    color: var(--text-link);
  }
}

.repeatSendCode {
  margin-right: 5px;
}

.resendText {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);

  text-align: center;
  margin-top: 20px;
}

.repeatCode {
  @include font-style-small;
}

.repeatCode {
  display: flex;
  justify-content: center;
  gap: 20px;
  text-align: center;
  color: var(--text-10);
  padding: 40px 0 4px 0;
}

.changeDataButton {
  @include font-style-small;
}

.changeDataButton {
  padding: 0;
  color: var(--text-link);
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    color: var(--text-primary);
  }

  &:active {
    color: var(--text-link);
  }
}

.error {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);

  color: var(--system-error);
  text-align: center;
  margin-top: 37px;
}
