@import './typography.variables';

@font-face {
  font-family: 'LatoWeb';

  src: url('../fonts/Lato-Regular.woff2') format('woff2');

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'LatoWeb';

  src: url('../fonts/Lato-Medium.woff2') format('woff2');

  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'LatoWeb';

  src: url('../fonts/Lato-Bold.woff2') format('woff2');

  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Currency';

  src: url('../fonts/Currencies.woff2') format('woff2');

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
