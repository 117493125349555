@import 'src/design-system/styles/typography.mixins';

.logoutBtn {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 16px 13px;
  @include font-style-default;
  color: var(--text-10);
  background-color: transparent;
  border: none;

  &:hover,
  &:focus {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: var(--shape-50);
  }
}

.logoutIcon {
  padding-right: 9px;
}
