@import 'src/design-system/styles/typography.mixins';

.stepsList {
  @include list-styles-reset();
  display: flex;
  min-height: 48px;
}

.stepsListItem {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.stepsListItemContent {
  @include font-style-super-small;

  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 24px;
  min-height: 24px;
  margin: 0;
  padding: 0;
  color: var(--text-20);
  border: 1px solid var(--text-20);
  border-radius: 50%;
  cursor: default;
}

.stepsListItem:not(:first-of-type) {
  flex-grow: 1;
}

.stepsListItem:not(:first-of-type)::before {
  content: '';
  height: 1px;
  width: 100%;
  margin: 0 12px;
  background-color: var(--shape-50);
}

.stepsListItemActive {
  color: var(--text-20);
  border: 2px solid var(--text-20);
}

.stepsListItemCompleted {
  background-color: var(--system-success);
  border: 1px solid var(--system-success);
}

.icon {
  color: var(--static-white);
}
