@import 'src/design-system/styles/typography.mixins';

.languageDropdown {
  & :global(.dropdown__control--menu-is-open) .controlIcon::after {
    background: var(--text-10);
    opacity: .15;
  }

  & :global(.dropdown__control) {
    min-width: unset;
    padding: 0;
    background: transparent;
    border: none;
    border-radius: 0;

    & :global(.dropdown__value-container) {
      padding: 0;
    }

    & :global(.dropdown__single-value) {
      display: none;
    }

    & :global(.dropdown__input-container) {
      width: 0;
    }

    & :global(.dropdown__indicators) {
      display: none;
    }
  }

  & :global(.dropdown__menu) {
    z-index: 101;
    min-width: 170px;
    margin: 1px 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  & :global(.dropdown__menu-list) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  & :global(.dropdown__option) {
    @include font-style-subtitle;
    display: flex;
    align-items: center;
    padding: 6px 12px;
  }
}

.controlIcon {
  position: relative;
  padding: 18px 12px 17px;
  width: 100%;
  height: 100%;
  z-index: 0;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:hover::after {
    background: var(--text-10);
    opacity: .15;
  }
}

.menuItemIcon {
  padding: 0 9px 0 0;
}

.menuItemLabel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
