.container {
  min-height: 32px;
  padding: 5px 8px 3px 8px;
  background-color: var(--shape-10);
  box-shadow: inset 0 -1px 0 var(--shape-50);
  position: sticky;
  z-index: 220;
}

.openedContainer {
  padding-bottom: 8px;
}

.closeButton {
  width: 100%;
  margin-top: 8px;
  border-color: var(--shape-50);
}
