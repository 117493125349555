@import 'src/design-system/styles/typography.mixins';

.container {
  padding: 0 8px 83px;
  margin-top: 12px;
}

.processing {
  padding-bottom: 28px;
}

.controls {
  display: flex;
  gap: 7px;
}

.inputContainer {
  display: flex;

  border-radius: 4px;

  &:hover {
    & .input,
    & .units {
      border-color: var(--text-20);
    }
  }
}

.units {
  border-right: 1px solid var(--shape-40);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.disabled .units {
  background-color: var(--shape-20);
}

.error {
  & .input,
  & .units {
    border-color: var(--system-error) !important;
  }
}

.errorMessage {
  margin-top: 2px;
}

.noMoney {
  margin-top: 2px;
}
