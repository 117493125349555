@import 'src/design-system/styles/typography.mixins';

.root {
  display: flex;
  align-items: center;
  flex-direction: column;

  & img {
    margin: 32px 0 24px;
  }
}

.title {
  @include font-style-subtitle($font-weight-bold);
  margin: 0 0 8px;
}

.text {
  @include font-style-small;
  text-align: center;
  margin: 0;
}
