@import 'src/design-system/styles/typography.mixins';
@import '../../../../../../grid.variables';

.lineDescription {
  @include font-style-tiny($font-weight-bold);
  color: var(--text-10);
  text-align: end;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 26px;
  padding-left: 12px;

  border-bottom: 1px solid var(--shape-50);
}

.border {
  border-bottom: 1px solid var(--shape-50);
}

.emptyBlock {
  width: $grid-row-last-column-width;
  border-bottom: 1px solid var(--shape-50);
}
