@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.newPassInputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 244px;
}

.passwordFormControl {
  width: 100%;
  margin-top: 10px;
}

.passwordInput {
  box-sizing: border-box;
  max-width: 500px;

  & input {
    box-sizing: border-box;
  }
}
