@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/layout-variables';
@import '../../../../../../grid.variables';

.matchLine {
  @include font-style-tiny($font-weight-bold);
  text-align: center;
  color: var(--text-10);

  width: $grid-default-content-width;

  padding: 8px 26px;

  border: 1px solid var(--shape-50);
  border-top: none;

  & .subtext {
    font-weight: $font-weight-regular;
    color: var(--text-20);
  }
}
