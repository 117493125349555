@import 'src/design-system/styles/typography.mixins';

.container {
  display: flex;
  align-items: center;
  @include font-style-subtitle($font-weight-regular);
  color: var(--text-10);
  gap: 8px;
  cursor: pointer;

  &:hover {
    color: var(--text-20);
  }
}

.hiddenBalance {
  display: inline-flex;
  align-items: center;
  margin: 0;
}

.hiddenBalanceValue {
  margin: 8px 6px 0 0;
}
