@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.container {
  margin-bottom: 14px;
}

.fieldInfo {
  @include font-style-tiny;

  display: inline-block;
}

.error {
  color: var(--system-error);
}
