.wrapper {
  position: relative;
  padding: 0 10px;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--text-30);
}
