@import 'src/design-system/styles/typography.mixins';

.containerLogout {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px;

  & .userName {
    @include font-style-default($font-weight: $font-weight-bold);
    color: var(--text-10);
    margin: 0;
    @include one-line-ellipsis;
    max-width: 225px;

    &:hover,
    &:focus {
      opacity: .8;
    }
  }

  & .buttonHidden {
    @include font-style-small;
    color: var(--text-link);
    border: none;
    cursor: pointer;
    background-color: transparent;
    padding: 0;

    &:focus-visible {
      color: var(--text-link);
    }

    &:hover {
      opacity: .8;
    }
  }
}
