@import 'src/design-system/styles/typography.mixins';

.root {
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  gap: 8px;
}

.title {
  @include font-style-tiny($font-weight-regular);

  margin: 0;
  text-align: center;
  color: var(--text-10);
}

.items {
  width: 100%;
}
