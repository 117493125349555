@import 'src/design-system/styles/typography.mixins';

.headerContent {
  @include font-style-h4($font-weight-regular);
  display: flex;
  align-items: center;
}

.title {
  width: 370px;
  margin: 0;
}
