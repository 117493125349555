@import 'src/design-system/styles/typography.mixins';

.modalBody {
  @include font-style-default;
}

.bodyInfo {
  max-width: 375px;
  padding-bottom: 15px;
}

.fieldInput {
  margin-bottom: 12px;
  max-width: 340px;
}
