@import 'src/design-system/styles/typography.mixins';

.main {
  position: relative;
  display: flex;
  align-items: center;

  max-width: 100%;

  & input {
    padding-left: 25px;
  }

  &::after {
    @include font-style-default;

    position: absolute;
    content: '+';
    top: 1px;
    left: 16px;
    margin: auto 0;
    width: 10px;
    line-height: 40px;
    color: var(--text-10);
  }
}

.disabledPlus {
  &::after {
    color: var(--text-30);
  }
}
