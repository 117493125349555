.link {
  margin-left: 4px;
  color: var(--text-link);
  text-decoration: underline;

  &:hover,
  &:focus {
    opacity: .8;
  }
}
