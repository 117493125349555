@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.conflictContainer {
  @include font-style-small($font-weight-medium);
  display: flex;
  flex-direction: column;
  padding: 8px;

  background-color: var(--shape-10);

  text-align: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.shakeWrapper {
  display: flex;
  flex-direction: column;
}

.title {
  @include font-style-small;
  color: var(--text-10);
}

.conflictButton {
  @include font-style-tiny;
  margin-top: 8px;
}

.container {
  padding: 0 12px 12px;
  background-color: var(--shape-10);

  &::before {
    content: '';

    display: block;
    height: 1px;
    width: 100%;
    background-color: var(--shape-50);
  }
}

.noMoneyComponent {
  color: var(--text-10);
}
