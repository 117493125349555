@import 'src/design-system/styles/typography.mixins';

.socialBlock {
  padding: 36px 84px 40px 24px;

  & p {
    @include font-style-extra-small;
    color: var(--text-20);
    margin: 0;
    padding-bottom: 8px;
  }
}

.iconMenuList {
  @include list-styles-reset;
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-column-gap: 10px;
  justify-content: center;
}

.iconItemLink {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 1px solid var(--text-30);
  border-radius: 50%;
  color: var(--text-30);

  &:hover,
  &:focus {
    color: var(--text-20);
  }
}
