@import 'src/design-system/styles/typography.mixins';

$option-padding-vertical: 7px;
$option-padding-horizontal: 12px;

.container {
  display: inline-flex;
  border: 1px solid var(--shape-50);
  padding: 4px;
  border-radius: 8px;
  gap: 4px;
}

.option {
  @include font-style-tiny;

  display: flex;
  flex-grow: 1;
  align-items: center;
  min-width: 0;
  border: 0;
  border-radius: 8px;
  color: var(--controls-segmented-text);
  background: var(--shape-10);
  padding: $option-padding-vertical $option-padding-horizontal;

  &:hover {
    background: var(--shape-50);
    color: var(--text-10);
  }

  &:focus-visible {
    padding: ($option-padding-vertical - 1px) ($option-padding-horizontal - 1px);
  }
}

.activeOption {
  background: var(--primary-90);
  color: var(--text-10);
  pointer-events: none;
}
