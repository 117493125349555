@mixin staticLight {
  --static-primary: #a6192e;
  --static-secondary: #efdf00;
  --static-white: #fff;
  --static-black: #000;
  --static-gray-10: #1e1e1e;
  --static-gray-20: #404040;
  --static-gray-40: #787878;
  --static-gray-100: #e3e3e3;
  --static-gray-110: #f2f2f2;
  --static-gray-120: #f9f8f8;
}

@mixin staticDark {
  --static-primary: #a6192e;
  --static-secondary: #efdf00;
  --static-white: #fff;
  --static-black: #000;
  --static-gray-10: #1e1e1e;
  --static-gray-20: #404040;
  --static-gray-40: #787878;
  --static-gray-100: #e3e3e3;
  --static-gray-110: #f2f2f2;
  --static-gray-120: #f9f8f8;
}
