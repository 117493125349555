@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.freeBet {
  & > p,
  & > span {
    color: var(--text-10);
  }
}

.dropdown {
  height: 100%;

  & :global(.dropdown__loading-indicator) {
    display: none;
  }

  & :global(.dropdown__value-container) {
    padding: 0;
  }

  & :global(.dropdown__single-value) {
    @include font-style-tiny($font-weight-medium);
    position: static;
    top: 0;
    max-width: none;
    margin-right: 4px;
    transform: none;
  }

  & :global(.dropdown__indicators) {
    position: relative;
    padding-right: 14px;
  }

  & :global(.dropdown__indicator) {
    padding: 0 4px 0 0;
    margin-left: -4px;
    position: absolute;
    left: 2px;

    & > span > svg {
      fill: var(--text-10);
    }
  }

  & :global(.dropdown__control) {
    height: 100%;
    min-width: 0;
    min-height: 0;
    padding: 0 0 0 8px;

    background-color: var(--shape-50);
    border: none;
    border-radius: 0;
    transition: none;

    &:hover {
      background-color: var(--shape-40);
    }
  }

  &:global(.dropdown__control--is-focused) {
    border: none;
    box-shadow: none;
  }

  & :global(.dropdown__control--is-disabled) {
    pointer-events: none;

    & :global(.dropdown__single-value) {
      color: var(--text-40);
    }

    & :global(.dropdown__indicator) svg {
      fill: var(--text-40);
    }
  }
}

.portal {
  & :global(.dropdown__menu) {
    right: 0;
    margin: 1px 0;
    width: auto;
    min-width: 109px;
    z-index: 105;

    & :global(.dropdown__menu-list) {
      min-width: 154px;
      padding: 0;
    }

    & :global(.dropdown__option) {
      @include font-style-tiny;
    }

    & :global(.dropdown__option--is-selected) {
      display: none;
    }
  }
}

.disabledFreeBets {
  @include font-style-tiny;
  display: flex;
  flex-direction: column;

  width: 154px;
  padding: 8px;
  margin: 0;

  color: var(--text-100);
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
}
