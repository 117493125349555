@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.settingsButton,
.closeButton {
  border: none;
  background: none;
  padding: 0;
}

.settingsButton {
  & path {
    fill: var(--text-20);
  }

  &:focus,
  &:hover {
    & path {
      opacity: .8;
    }
  }
}

.closeButton {
  position: absolute;
  top: 13px;
  right: 13px;
  display: flex;

  & path {
    fill: var(--text-10);
  }

  &:focus,
  &:hover {
    opacity: .8;
  }
}

.container {
  position: absolute;
  top: 0;
  right: 0;

  width: 100%;
  padding: 8px 8px 7px;

  background: var(--shape-10);
  box-shadow: var(--shadow-2);

  z-index: 210;
  transition: transform ease-out 200ms;
}

.expanded {
  transform: translateY(0) !important;
  transition: transform ease-in 200ms;
}

.title {
  @include font-style-default($font-weight-bold);

  margin: 0;

  color: var(--text-10);
}

.subtitle {
  @include font-style-tiny($font-weight-regular);
  padding-bottom: 8px;
  margin: 0;
  text-align: center;

  color: var(--text-10);
}

.title::after {
  content: '';
  display: block;

  width: 100%;
  height: 1px;
  margin: 8px 0;

  background: var(--shape-50);
}

.menuPreferences {
  width: 100%;
}

.refreshToggle {
  padding-top: 14px;
}

.toggleDescription {
  @include font-style-tiny;
  margin-top: 4px;
  margin-bottom: 0;
  padding-left: 36px;

  line-height: $line-height-super-small;
  color: var(--text-30);
}
