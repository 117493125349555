.skeletonItem {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.skeletonHeader {
  display: flex;
  margin-bottom: 8px;
}

.secondItem {
  margin-left: 8px;
}

.wrapperBetting {
  display: grid;
  grid-template-columns: 1fr max-content max-content;
  background-color: var(--shape-10);
  border-top: 2px solid var(--text-40);
  border-bottom: 1px solid var(--shape-20);
}

.matchDetails {
  padding: 6px 8px 4px 12px;
  border-right: 1px solid var(--shape-20);
}

.skeletonMatch {
  margin: 6px 0;
}

.rightCell {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-column-gap: 8px;
  padding: 8px 8px 6px;
  border-right: 1px solid var(--shape-20);
  border-top: none;
}

.rightEnd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 36px;
}

.rightEndItem {
  padding: 10px;

  &:first-child {
    border-bottom: 1px solid var(--shape-20);
  }
}
