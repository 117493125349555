@import 'src/design-system/styles/typography.mixins';
@import '../Input/Input.module.scss';

.formControl {
  min-height: 60px;

  & .label {
    margin-bottom: 0;
    width: 100%;
    color: var(--text-20);

    & .labelText {
      @include font-style-tiny;

      display: block;
      font-weight: bold;
      text-transform: uppercase;
      margin-left: 0;
      margin-bottom: 4px;
      width: 100%;
      max-width: $input-width;
      overflow: hidden;
    }
  }

  & .caption {
    @include font-style-tiny;
    color: var(--text-20);
    display: block;
    margin-top: 2px;
    margin-bottom: 0;

    &.error {
      color: var(--system-error);
    }
  }

  & a.caption {
    color: var(--text-link);
    cursor: pointer;

    &:hover,
    &:focus {
      opacity: .8;
    }
  }
}

.inline {
  width: initial;
  display: flex;
  align-items: center;

  & .label {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    width: auto;

    & .labelText {
      width: auto;
      margin: auto 12px auto 0;

      max-width: 200px;
    }
  }

  & .caption {
    @include font-style-tiny;

    display: flex;
    overflow: hidden;
    width: auto;
    max-width: 200px;
    margin-left: 8px;
    margin-top: 0;

    & > span {
      margin: auto 0;
    }
  }
}

.labelControl {
  &.formControl {
    min-height: 82px;
  }
}

// sizes
// the default size - medium
// styles for default size are above in .formControl class
.smallControl {
  & .label .labelText {
    @include font-style-super-small($font-weight-bold);
  }

  & .caption {
    @include font-style-super-small;
    line-height: $line-height-tiny;
  }
}

.largeControl {
  & .label .labelText {
    @include font-style-small($font-weight-bold);
  }

  & .caption {
    @include font-style-small;
  }
}
