@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';
@import '../../grid.variables';

.stake {
  position: relative;
  width: $grid-stake-width;
  border: 1px solid var(--shape-50);
  border-radius: 4px;
  overflow: hidden;

  &:hover {
    & .disabledIcon {
      background-color: var(--system-error);
    }
  }

  & button:first-child {
    border-bottom: 1px solid var(--shape-50);
  }
}

:global(.three-bets) {
  & button:first-of-type,
  button:last-of-type {
    padding-right: 55px;
  }

  & button:nth-child(2) {
    position: absolute;
    z-index: 1;
    right: 0;
    top: -10px;
    bottom: -10px;

    width: 47px;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 1px solid var(--shape-50);
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    border-right: none;

    & div:first-child {
      width: 100%;
    }

    & span:first-child {
      text-align: center;
    }
  }
}

.disabledIcon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  padding-right: 1px;

  background-color: var(--text-30);
  color: var(--static-white);

  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.betPrice {
  color: var(--text-10);
  font-weight: $font-weight-medium;
}
