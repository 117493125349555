@import 'src/design-system/styles/typography.mixins';

.root {
  @include font-style-tiny;
  display: flex;
  justify-content: center;
  padding: 8px;
  background: var(--shape-20);
  border-top: 1px solid var(--shape-40);
}

.chevronRightIcon {
  display: flex;
  align-items: center;
}
