@import 'src/design-system/styles/typography.mixins';

.prematchPeriodLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include font-style-small;
  color: var(--text-10);

  & time:last-child {
    @include font-style-h3($font-weight-medium);
  }
}
