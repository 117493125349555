@import 'src/design-system/styles/typography.mixins';

.root {
  padding: 0 37px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 165px;
  overflow: hidden;
  border-bottom: 1px solid var(--shape-50);
}

.title {
  @include font-style-h4($font-weight-medium);
  color: var(--text-10);
  padding: 16px 0;
  margin: 0;
  width: 305px;
}

.phone {
  position: absolute;
  display: flex;
  justify-content: center;
  // Если вместо bottom указывать top то начинает ехать верстка при изменении высоты баннера
  bottom: -87px;
  right: 50px;
  width: 133px;
  height: 236px;
  padding-top: 27px;
  border: 4px solid var(--static-black);
  border-radius: 20px;
  box-shadow: 0 0 0 1px var(--static-white);
  background: var(--static-white);

  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: var(--static-black);
  }
}
