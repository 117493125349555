
$color-green-haze: #0c8e4b;
$color-clay-creek: #867650;
$color-brown-rust: #9b5238;
$color-dove-gray: #6e6e6e;
$color-gigas: #4737a0;
$color-mojo: #c15b3f;
$color-solid-pink: #7b3a3a;
$color-calypso: #437b93;
$color-irish-coffee: #734834;
$color-pirate-gold: #b17d18;
$color-mosque: #1d6d72;
$color-blue-wave: #306fcc;
$color-plum: #69356b;
$color-san-marino: #446094;

$sportColors: (
  soccer: $color-green-haze,
  tennis: $color-mojo,
  table-tennis: $color-mosque,
  basketball: $color-brown-rust,
  ice-hockey: $color-calypso,
  volleyball: $color-pirate-gold,
  chess: $color-dove-gray,
  rugby: $color-san-marino,
  futsal: $color-dove-gray,
  baseball: $color-green-haze,
  boxing-and-mma: $color-solid-pink,
  handball: $color-pirate-gold,
  american-football: $color-green-haze,
  exclusive: #d4ebfc,
  water-polo: $color-blue-wave,
  lottery: $color-dove-gray,
  snooker: $color-mosque,
  race: $color-irish-coffee,
  e-sport: $color-plum,
  cs-go: $color-plum,
  dota: $color-plum,
  hockey-with-ball: $color-calypso,
  beach-volleyball: $color-clay-creek,
  field-hockey: $color-green-haze,
  badminton: $color-mojo,
  default: #656565,
);

$servingTeamIcons: (
  tennis: '../../../../design-system/images/serving-team/tennis.png',
  volleyball: '../../../../design-system/images/serving-team/volleyball.png',
);
