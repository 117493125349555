
.spinnerPosition {
  margin-right: 30px;
}

.signList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 24px;

  & > li {
    display: flex;
    align-items: center;
  }
}

.signIn {
  color: var(--text-10);
  cursor: pointer;

  &:focus,
  &:hover,
  &:focus-visible {
    color: var(--text-20);
  }
}

.registrationBtn,
.signing {
  margin-left: 15px;
}
