@import 'src/design-system/styles/typography.mixins';

.list {
  @include list-styles-reset;
}

.item {
  border-radius: 8px;
  overflow: hidden;
}

.link {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 8px;
  @include font-style-small($font-weight-bold);
  color: var(--text-10);

  &:hover {
    background-color: var(--primary-110);

    & .label,
    & .icon {
      color: var(--text-primary);
    }
  }
}

.link:focus-visible,
.itemSelected {
  & .link {
    background-color: var(--shape-00);
  }

  & .label,
  & .icon {
    color: var(--text-primary);
  }
}

.label,
.caption {
  @include one-line-ellipsis;
  margin-top: 2px;
}

.icon {
  flex-shrink: 0;
  color: var(--text-30);
  border-radius: 4px;
  overflow: hidden;
}

.iconWithBackground {
  background-color: var(--accent-00);
}

.matchesCount {
  margin-left: auto;
  margin-top: 2px;
}
