@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.chainEntry {
  width: 100%;
}

.selectedEntry {
  opacity: .5;
}

.entryName {
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 4px;
}

.name {
  @include font-style-small($font-weight-bold);
  color: var(--text-10);
}

.selected {
  @include font-style-small($font-weight-bold);
  margin-left: 4px;
  color: var(--text-10);
}

.maxWinning {
  display: inline-flex;
  align-items: center;
  height: 20px;
  background-color: var(--text-20);
}

.amount {
  @include font-style-tiny($font-weight-medium);

  margin-left: 4px;
}

.matches {
  display: flex;
  flex-direction: column;
}

.matchName {
  @include font-style-small;
  padding: 0;
  margin: 0;
  color: var(--text-20);
}

.maxNumberOfVisibleText {
  @include font-style-small;
  color: var(--text-40);
}
