@import '../../../grid.variables';

.matchRowContent {
  position: relative;
}

.matchRow {
  display: grid;
  grid-template-columns: 1fr max-content max-content;
  position: sticky;
  top: 72px;
  z-index: 9;
  background-color: var(--shape-10);

  &:hover > div:nth-child(1),
  &:hover > div:nth-child(2),
  &:hover > button:nth-child(2),
  &:hover > a:nth-child(2),
  &:hover > div:nth-child(3) {
    background-color: var(--grid-bet-bg);
  }

  &:hover ul {
    background: linear-gradient(270deg, var(--grid-bet-bg) 90%, transparent 100%);
  }

  &:hover ul > li > div {
    &::after {
      background: var(--grid-bet-bg);
    }
  }
}

.innerLineContainer {
  display: grid;
  grid-template-columns: 1fr max-content max-content;
}

.detailsCell {
  padding: 8px 8px 8px 12px;

  min-width: 0;
  border-bottom: 1px solid var(--shape-50);
  z-index: 2;
}

.mainLineStakes {
  margin: 0 4px;
}
