@import 'src/design-system/styles/typography.mixins';

.root {
  display: flex;
  flex-direction: column;
}

.link {
  @include font-style-default;

  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 16px 10px 48px;
  border: none;
  background-color: transparent;
  color: var(--text-10);

  & span {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    color: var(--text-10);
  }

  &:hover:not(.active) {
    background-color: var(--shape-50);
  }
}

.active {
  background-color: var(--shape-10);
}
