@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/themes/palettes/sportColors';

.sport {
  @include font-style-tiny;

  display: flex;
  align-items: center;
  margin-top: 6px;

  color: var(--text-10);

  &::before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    margin-right: 6px;

    border-radius: 12px;
  }
}

@each $name, $value in $sportColors {
  .#{$name}::before {
    background-color: $value;
  }
}
