.root {
  width: 100%;
  background-color: var(--text-link);
  color: var(--static-white);
}

.root[disabled] {
  background-color: var(--text-link);
  opacity: 1;
}

.spinner {
  padding: 0;
}

.root:hover {
  background-color: var(--text-link);
  color: var(--static-white);
  opacity: .8;
}
