@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables.scss';

.title {
  @include font-style-subtitle($font-weight: $font-weight-bold);
  color: var(--text-20);
  margin-top: 0;
  margin-bottom: 32px;
}

.content {
  display: flex;
  position: relative;
}

.divider {
  margin-bottom: 65px;

  &::after {
    content: '';
    width: 100%;
    height: 1px;
    background: var(--shape-50);
    position: absolute;
    bottom: -32px;
    left: 0;
  }
}
