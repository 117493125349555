.background {
  fill: var(--shape-00);
}

.circle {
  fill: var(--shape-50);
}

.skeleton {
  stroke: var(--text-30);
}

.firstTitle {
  stroke: var(--system-warning);
}

.title {
  stroke: var(--primary-60);
}

.star {
  fill: var(--controls-segmented-bg);
}

.roundStar {
  stroke: var(--controls-segmented-bg);
}

.loupe {
  fill: var(--primary-60);
}
