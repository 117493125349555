@import 'src/design-system/styles/typography.mixins';

.barContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 1px 0 0 0;
  overflow: hidden;
}

.barButton {
  @include font-style-tiny();
  line-height: 15px;

  color: var(--text-10);
  white-space: nowrap;
  background: none;
  border: none;
  border-radius: 10px;
  padding: 1px 4px;
}

.preset {
  &:hover,
  &:focus {
    background: var(--primary-60);
    color: var(--static-white);
  }

  &[disabled] {
    pointer-events: none;
    color: var(--text-30);
  }
}

.freebet {
  &:hover {
    color: var(--text-100);
    background: var(--primary-60);
  }
}

.freebetDisabled {
  color: var(--text-30);
  cursor: default;

  &:hover {
    opacity: 1;
  }
}
