@import 'src/design-system/styles/typography.mixins';

.body {
  padding: 6px 24px 0;
}

.reminder {
  @include font-style-small;
  color: var(--text-30);
  background-color: var(--shape-20);
  border-radius: 12px;
  margin-bottom: 14px;
  padding: 16px 20px;
}

.personalList {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 5px;
  padding-left: 0;
}

.personalItem:not(:last-of-type) {
  margin-bottom: 5px;
}
