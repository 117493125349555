.container {
  display: flex;
  align-items: center;
  color: var(--text-20);
}

.amount {
  margin: 0 4px;
}

.tooltip {
  max-width: 256px;

  & > div {
    padding: 18px 16px 14px 15px;
  }
}

.tooltipIcon {
  color: var(--text-30);
}
