@import 'src/design-system/styles/typography.mixins';

.root {
  @include font-style-super-small;

  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  color: var(--text-30);

  & p {
    margin: 0;
  }
}

.notice {
  display: flex;
  gap: 24px;
}

.icon {
  min-width: 40px;
  height: 40px;
}
