@import 'src/design-system/styles/typography.mixins';

.dropdown {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 145px;
  height: 100%;

  & :global(.dropdown__menu) {
    margin: 0;
  }

  & :global(.dropdown__single-value) {
    @include font-style-small($font-weight-bold);

    color: var(--text-20);
  }

  & :global(.dropdown__indicator) {
    margin-left: -4px;
  }

  & :global(.dropdown__control) {
    height: 100%;
    min-width: 0;
    min-height: 0;

    border: none;

    &:hover {
      & :global(.dropdown__single-value) {
        opacity: .8;
      }

      & :global(.dropdown__indicator) svg {
        opacity: .8;
      }
    }
  }

  & :global(.dropdown__control--is-disabled) {
    background-color: transparent;
  }
}
