@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';
@import '../../grid.variables';

.tableContainer {
  display: flex;

  padding-right: $grid-row-last-column-width;
  padding-left: 8px;

  & > div {
    width: $grid-stake-width;
    margin-right: 8px;
  }
}

.select {
  & :global(.dropdown__control) {
    background-color: transparent;

    flex-wrap: nowrap;
    justify-content: center;
    min-width: unset;
    min-height: unset;
    height: auto;
    border: none;
    padding: 0;

    &:hover {
      opacity: .8;
    }
  }

  & :global(.dropdown__value-container) {
    @include font-style-extra-small;
    text-transform: uppercase;

    flex-wrap: nowrap;
    flex: unset;
    padding: 0;
  }

  & :global(.dropdown__single-value) {
    color: var(--text-20);
    letter-spacing: .8px;
    position: static;
    transform: unset;

    max-width: unset;
    margin-right: 0;
  }

  & :global(.dropdown__indicators) {
    align-self: unset;
  }

  & :global(.dropdown__indicator) {
    padding: 0;
  }
}

.portal {
  & :global(.dropdown__menu) {
    left: 50%;
    transform: translateX(-50%);
    width: 148px;
  }
}
