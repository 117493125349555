@import 'src/design-system/styles/typography.mixins';

$submissionStateBackgrounds: (
  processing: var(--shape-20),
  accepted: var(--system-success),
  rejected: var(--system-error),
  failed: var(--system-error),
);

$submissionStateColors: (
  processing: var(--text-20),
  accepted: var(--system-success),
  rejected: var(--system-error),
  failed: var(--system-error),
);

$submissionStateOpacity: (
  processing: 1,
  accepted: .25,
  rejected: .25,
  failed: .25,
);

.submissionContainer {
  position: relative;
  padding: 16px;
  width: 100%;

  &.single .contentWrapper {
    min-height: 22px;
  }

  &::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border-radius: 4px;
  }
}

.contentWrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 22px;
  min-height: 50px;
}

.submissionTitle {
  @include font-style-small;
  margin: 0;
}

@each $submissionStatus, $background in $submissionStateBackgrounds {
  .submissionContainer.#{$submissionStatus} {
    &::before {
      background: $background;
    }
  }
}

@each $submissionStatus, $opacity in $submissionStateOpacity {
  .submissionContainer.#{$submissionStatus} {
    &::before {
      opacity: $opacity;
    }
  }
}

@each $submissionStatus, $color in $submissionStateColors {
  .submissionTitle.#{$submissionStatus} {
    color: $color;
  }
}
