@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/layout-variables';

.footerContainer {
  background-color: var(--shape-10);
  margin: 0 $content-indent-left 0 $content-indent-right;
  border-radius: 20px 20px 0 0;
}

.headerMenuList {
  @include list-styles-reset;
  display: flex;
  flex-wrap: wrap;
  column-gap: 21px;
  row-gap: 6px;
  justify-content: center;
  width: 100%;
  padding: 15px 20px;
  border-bottom: 1px solid var(--shape-50);
}

.headerListItem {
  @include font-style-tiny;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    background: var(--text-40);
    top: 8px;
    right: -14px;
    transform: translate(-50%, -50%);
  }
}

.depositLink {
  cursor: default;
  pointer-events: none;
}

.infoBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 12px 24px 12px;
  border-top: 1px solid var(--shape-50);

  & p {
    @include font-style-super-small;
    color: var(--text-30);
    margin: 0;
  }
}

.license {
  @include font-style-super-small;
  color: var(--text-30);
  padding: 16px;
  margin: 0;
}

.informationContainer {
  display: flex;
  align-items: flex-start;
}
