@import 'src/design-system/styles/typography.mixins';

.paymentsBlock {
  @include list-styles-reset;
  padding: 36px 0 40px;

  & p {
    @include font-style-extra-small;
    color: var(--text-20);
    margin: 0;
    padding-bottom: 12px;
  }
}

.paymentImg {
  width: 25px;
  height: 21px;
}
