@import 'src/design-system/styles/typography.mixins';

.body {
  padding: 6px 24px 0;
}

.list {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 5px;
  padding-left: 0;
}

.item:not(:last-of-type) {
  margin-bottom: 5px;
}
