.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-basis: 50%;

  &:not(:first-of-type) {
    margin-top: 24px;
  }
}
