@mixin textLight {
  --text-10: #000;
  --text-20: #4d4d4d;
  --text-30: #787878;
  --text-40: #bababa;
  --text-100: #fff;
  --text-link: #0f63c4;
  --text-visited-link: #6c36b0;
  --text-primary: #a6192e;
  --text-logo-tmp: #000;
}

@mixin textDark {
  --text-10: rgba(255, 255, 255, .86);
  --text-20: #949494;
  --text-30: #656565;
  --text-40: #363636;
  --text-100: #121212;
  --text-link: #457ab7;
  --text-visited-link: #6d469f;
  --text-primary: #d2c918;
  --text-logo-tmp: #f8ff13;
}
