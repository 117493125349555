@import 'src/design-system/styles/typography.mixins';

.promoLink {
  display: block;
  @include font-style-default;
  background-color: var(--button-primary-default);
  color: var(--text-10);
  margin: 8px 16px;
  padding: 8px 16px;
  border-radius: 4px;
  text-align: center;

  &:hover,
  &:focus {
    color: var(--text-10);
    background: var(--button-primary-hover);
  }

  &:active,
  &.active {
    background: var(--button-primary-pressed);
  }
}
