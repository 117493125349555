.container {
  position: relative;
  padding: 8px 12px;

  background: var(--shape-10);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.toggle {
  justify-content: center;
}

.input {
  margin-top: 12px;
}

.checkbox {
  margin-top: 8px;
  margin-bottom: 2px;
}

.disabledCheckbox {
  pointer-events: none;
}

.disabledTextBar > span {
  color: var(--text-30);
}
