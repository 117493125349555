@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.tabs {
  display: flex;
  border-bottom: 1px solid var(--shape-50);

  & > button:last-child {
    margin-right: 0;
  }
}

.tab {
  padding: 0;
  margin-right: 16px;

  color: var(--text-10);
  background-color: transparent;
  border: none;

  &::after {
    content: '';

    display: block;

    height: 2px;
    width: 100%;
    margin-top: 2px;

    background-color: transparent;
  }

  &:focus-visible {
    color: var(--text-20);

    &::after {
      background-color: var(--text-primary);
    }
  }

  &:hover {
    color: var(--text-20);

    &::after {
      background-color: var(--text-primary);
    }
  }
}

.small {
  @include font-style-small;
}

.medium {
  @include font-style-default;

  &::after {
    margin-top: 6px;
  }
}

.large {
  @include font-style-subtitle($font-weight-regular);
  margin-right: 38px;

  &::after {
    margin-top: 11px;
  }
}

.disabled {
  pointer-events: none;
  color: var(--text-30);

  &::after {
    background-color: var(--text-30);
  }
}

.active {
  color: var(--text-primary);

  &::after {
    background-color: var(--text-primary);
  }
}
