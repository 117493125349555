@import 'src/design-system/styles/typography.mixins';
@import 'src/styles/animation-variables';

$checkboxWidth: 16px;
$checkboxHeight: 16px;

.root {
  position: relative;
  cursor: pointer;
  user-select: none;

  display: flex;
  margin: 0;

  & .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;

    position: relative;

    &::before,
    &::after {
      cursor: pointer;
      content: '';
    }

    &::before {
      display: inline-block;

      flex: 1 0 auto;

      width: $checkboxWidth;
      height: $checkboxHeight;

      border: 1px solid var(--text-30);
      border-radius: 2px;

      background-color: var(--shape-10);
    }

    &::after {
      display: block;
      position: absolute;

      left: 50%;
      top: 50%;

      height: 11px;
      width: 12px;

      background-size: cover;

      transform: translate(50%, 50%) scale(0);
      transform-origin: center center;
    }
  }

  & .input {
    @include visuallyHidden;
  }

  & > input[type=checkbox]:hover:not([disabled]) + .wrapper::before {
    border: 1px solid var(--text-20);
  }

  & > input[type=checkbox]:focus:not([disabled]) + .wrapper::before {
    border: 1px solid var(--text-link);
  }
}

.checked {
  & > input[type=checkbox]:focus:not([disabled]) + .wrapper::before {
    background-color: var(--primary-60);
    border: 1px solid var(--text-link);
    border-radius: 2px;
  }

  & > input[type=checkbox]:hover:not([disabled]) + .wrapper::before {
    background-color: var(--primary-60);
    border: none;
    opacity: .8;
  }

  & .wrapper::before {
    background-color: var(--primary-60);
    border: none;
  }

  & .wrapper .checkIcon {
    position: absolute;
    color: var(--text-100);
  }
}

.labelText {
  color: var(--text-10);
  margin: 0 0 0 8px;

  &:hover {
    color: var(--text-20);
  }
}

.labelDefaultSize {
  @include font-style-small;
}

.labelLargeSize {
  @include font-style-default;
}

.disabled {
  cursor: not-allowed;

  & > input[type=checkbox] {
    pointer-events: none;
  }

  & .wrapper::before,
  .wrapper::after {
    cursor: not-allowed;
  }

  & .wrapper::before {
    background-color: var(--shape-50);
    border: 1px solid var(--text-40);
  }

  & .wrapper .checkIcon {
    color: var(--text-30);
  }

  &.checked > .wrapper::before {
    background-color: var(--shape-50);
    border: 1px solid var(--text-40);
  }

  & .labelText {
    color: var(--text-40);
  }
}
