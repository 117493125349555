@import 'src/design-system/styles/typography.mixins';
@import '../../../../styles/animation-variables';

.itemDropdownMenu {
  & > a,
  > span {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 6px 0;
    min-height: 54px;
    transition: background $defaultTransitionDuration $smoothTransitionTiming;

    & .label {
      @include font-style-default;
      color: var(--text-10);
      padding: 0 16px;
    }
  }

  & > a {
    &:hover,
    &:focus {
      background: var(--shape-50);
    }
  }

  &:global(.disabled) {
    color: var(--text-30);
    background-color: transparent;

    & span {
      cursor: default;
      color: var(--text-30);
    }
  }

  &:last-child {
    margin-top: 12px;
  }
}

.topDivider {
  border-top: 1px solid var(--shape-50);
}

.linkIcon {
  padding-right: 9px;
}

.dropdownHeaderLabel {
  position: relative;

  height: 100%;
  background: transparent;
}

.dropdownMenuBody {
  width: 100%;
  position: absolute;
  right: 0;
  top: 100%;
  box-shadow: var(--shadow-2);
  border: 1px solid var(--shape-50);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
  padding: 14px 0 0 0;
  margin: 1px 0 0 auto;
  z-index: 101;
  list-style: none;
  background-color: var(--shape-10);

  & li:last-child {
    border: none;
  }

  & a {
    text-decoration: none;
  }
}

.dropdownHeaderButton {
  color: var(--text-10);
  background-color: transparent;
  border: none;
  height: 100%;
  padding: 0;

  &:hover {
    opacity: .8;
  }
}

.arrowDown {
  position: relative;
  top: 2px;
}
