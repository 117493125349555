@import 'src/design-system/styles/typography.mixins';

$padding-default-vertical: 8px;
$padding-default-horizontal: 16px;

$padding-small-vertical: 4px;
$padding-small-horizontal: 12px;

$padding-large-vertical: 14px;
$padding-large-horizontal: 16px;

.button {
  @include font-style-default;

  display: inline-flex;
  gap: 8px;

  justify-content: center;
  min-width: 105px;

  border: none;
  border-radius: 4px;
  padding: $padding-default-vertical $padding-default-horizontal;
  font-family: $font-family-default;
  text-align: center;
  color: var(--text-10);

  cursor: pointer;

  &[disabled] {
    cursor: not-allowed;
  }

  & .icon {
    flex: 0 0 auto;
  }

  // -- Modifiers -- //

  &:focus-visible {
    border: 1px solid var(--text-link);
    padding: ($padding-default-vertical - 1px) ($padding-default-horizontal - 1px);
  }

  &.block {
    display: flex;
    width: 100%;
  }

  &.disabled {
    opacity: .85;

    & > * {
      opacity: .36;
    }
  }

  &.loading {
    position: relative;

    & > *:not(.spinner) {
      visibility: hidden;
    }

    & .spinner {
      position: absolute;
      top: 0;
    }
  }
}

// -- Sizing -- //

.sizeSmall {
  @include font-style-small;

  padding: $padding-small-vertical $padding-small-horizontal;
  gap: 4px;

  & .icon {
    padding: 2px 0;
  }

  &:focus {
    padding: ($padding-small-vertical - 1px) ($padding-small-horizontal - 1px);
  }
}

.sizeLarge {
  @include font-style-subtitle;

  padding: $padding-large-vertical $padding-large-horizontal;

  &:focus {
    padding: ($padding-large-vertical - 1px) ($padding-large-horizontal - 1px);
  }
}


// -- Intentions -- //

.intentDefault {
  border: 1px solid var(--text-40);
  padding: ($padding-default-vertical - 1px) ($padding-default-horizontal - 1px);

  background: transparent;
  color: var(--text-10);

  &:hover {
    color: var(--text-10);
    background: var(--shape-20);
  }

  &:active,
  &.active {
    background: var(--shape-30);
  }

  &.disabled,
  &.disabled:active,
  &.disabled.active {
    border: none;
    padding: $padding-default-vertical $padding-default-horizontal;

    background: var(--shape-20);
  }

  &.loading,
  &.loading:hover,
  &.loading:focus {
    background: var(--shape-30);
  }

  &.sizeSmall {
    padding: ($padding-small-vertical - 1px) ($padding-small-horizontal - 1px);

    &.disabled {
      padding: $padding-small-vertical $padding-small-horizontal;
    }
  }

  &.sizeLarge {
    padding: ($padding-large-vertical - 1px) ($padding-large-horizontal - 1px);

    &.disabled {
      padding: $padding-large-vertical $padding-large-horizontal;
    }
  }
}

.intentPrimary {
  background: var(--button-primary-default);
  color: var(--text-10);

  &:hover {
    background: var(--button-primary-hover);
    color: var(--text-10);

    &.disabled {
      background: var(--button-primary-default);
    }
  }

  &.loading,
  &:active,
  &.active {
    background: var(--button-primary-pressed);
  }
}

.intentCallToAction {
  background: var(--button-cta-default);
  color: var(--text-100);

  &:hover {
    background: var(--button-cta-hover);
    color: var(--text-100);

    &.disabled {
      background: var(--button-cta-default);
    }
  }

  &.loading,
  &:active,
  &.active {
    background: var(--button-cta-pressed);
  }
}

.intentSuccess {
  background: var(--system-success);
  color: var(--static-white);
}

.intentWarning {
  background: var(--system-warning);
  color: var(--static-white);
}

.intentDanger {
  background: var(--system-error);
  color: var(--static-white);
}
