@import '../PersonalSubmodules.module.scss';

.widgetContent {
  overflow: hidden;
  padding: 0;
}

.spinner {
  height: 100%;
}

.item {
  border-bottom: 1px solid var(--shape-50);

  &:last-child {
    border-bottom: 0;
  }
}
