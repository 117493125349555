@import 'src/design-system/styles/typography.variables';

.preferences {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 32px;
  padding: 0 8px;
  border-bottom: 1px solid var(--shape-50);
}

.oneClickActive {
  box-shadow: none;
}
