@import './themes/theme.variables';

:root {
  @include light;

  //@media (prefers-color-scheme: dark) {
  //  @include dark;
  //}
}

.dark {
  @include dark;
}

.light {
  @include light;
}
