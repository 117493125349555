@import 'src/design-system/styles/link.mixins.scss';

.modalBody {
  padding: 0;

  & a {
    @include link-visited-style();
  }
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalBodyScroll {
  height: 430px;
  padding: 16px 24px;
}

.footerCancelButton {
  min-width: 88px;
}

.content h3:first-child {
  margin-top: 0;
}
