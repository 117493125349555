@import 'src/design-system/styles/typography.mixins';

.root {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 32px;
  height: 100%;
}

.stepList {
  @include font-style-small;
  @include list-styles-reset;
  list-style: decimal;
  list-style-type: decimal;
  padding-left: 16px;
}

.instruction {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
