.root {
  display: flex;
  align-items: center;
  margin-left: 6px;

  & > a,
  & > button:not(:last-child) {
    margin-right: 6px;
  }
}

.matchStreamingCtrlIcon {
  border: none;
  background-color: transparent;
  padding: 0;
  color: var(--text-30);

  &:hover {
    color: var(--text-20);
  }
}
