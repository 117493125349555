@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.bet {
  & .matchRow:first-child {
    border-top: 1px solid var(--shape-50);
  }
}

.matchRow {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 64px;
  transition: background-color .2s ease-in;

  &:hover {
    background-color: var(--shape-30);
  }
}

.finished {
  opacity: .5;
}

.match {
  padding: 12px 0 12px 16px;
}

.matchName {
  @include font-style-small($font-weight-bold);
  max-width: 520px;
  margin-right: 8px;
  color: var(--text-10);
  text-decoration: none;
}

a.matchName:hover {
  color: var(--text-primary);
}

.matchTitle {
  display: contents;
  padding-bottom: 2px;

  & .liveBadge {
    padding: 1px 4px;
    font-size: 11px;
  }
}

.highlight {
  padding: 2px 0 2px;
  background-color: var(--shape-50);
}

.matchDetails {
  display: flex;
}

.matchTime,
.matchScores,
.comma {
  @include font-style-tiny;
  color: var(--text-30);
  font-size: 11px;
}

.matchScores {
  padding-left: 16px;
  margin: 0;
}

.matchScores span:only-child {
  display: none;
}

.betPrices {
  display: flex;
  align-items: center;
  padding-right: 16px;
}

.price {
  @include font-style-tiny;
  @include one-line-ellipsis;
  color: var(--text-10);
  text-align: center;

  background: transparent;

  flex: 1;
  padding: 4px;
  border: none;
  outline: none;

  &:hover {
    background-color: var(--shape-50);
  }

  &.selectedBet {
    background-color: var(--static-secondary);
    color: var(--static-black);
  }
}

.stakesContainer {
  display: flex;
}

.subHeader {
  width: 100%;
  height: 24px;
  padding: 2px 16px;
  color: var(--text-20);
  background-color: var(--shape-20);
  box-shadow: inset 0 -1px 0 0 var(--shape-50);

  & a {
    @include font-style-tiny($font-weight-medium);
    color: var(--text-20);

    &:hover {
      color: var(--text-primary);
    }

    &:first-of-type {
      margin-right: 8px;
    }

    &:nth-of-type(2) {
      margin: 0 8px;
    }

    &:last-of-type {
      margin-left: 8px;
    }
  }
}

.stake {
  position: relative;

  background-color: var(--shape-20);
  display: flex;
  flex-direction: column;

  width: 88px;
  height: 48px;
  margin: 0 4px;
  border: 1px solid var(--shape-50);
  border-radius: 3px;
  overflow: hidden;

  &.disabled {
    & .betName,
    & .price {
      color: var(--text-40);
    }

    & button {
      user-select: none;
      cursor: default;

      &:hover {
        background-color: var(--grid-bet-bg);
      }
    }
  }

  &:hover .disabledIcon {
    background-color: var(--system-error);
  }
}

.stakeBlank {
  background-color: var(--shape-20);
  display: flex;
  flex-direction: column;

  width: 88px;
  height: 48px;
  margin: 0 4px;
  border: 1px solid var(--shape-50);
  border-radius: 3px;
  overflow: hidden;

  & span {
    color: var(--text-40);

    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & span:first-child {
    border-bottom: 1px solid var(--shape-50);
  }
}

.disabledIcon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;

  color: var(--static-white);
  background-color: var(--text-30);

  border-top-right-radius: 25%;
  border-bottom-right-radius: 25%;
}

.betName {
  @include font-style-tiny;
  color: var(--text-20);
  text-align: center;
  padding: 4px 6px;
  @include one-line-ellipsis;

  flex: 1;
  border-bottom: 1px solid var(--shape-50);
}

.betsCount {
  @include font-style-tiny($font-weight-medium);
  text-align: center;

  width: 38px;
  padding-left: 8px;
}
