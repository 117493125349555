.root {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.text {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-10);
}
