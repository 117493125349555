@import 'src/design-system/styles/typography.variables';
@import 'src/design-system/styles/typography.mixins';

.container {
  position: relative;
  padding: 8px 12px;

  background: var(--shape-10);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.submission {
  opacity: 1;
}

.price {
  @include font-style-small;
  display: flex;
  justify-content: center;
  text-align: center;
  color: var(--text-10);
}

.priceValue {
  margin-left: 8px;
  height: inherit;
}

.disabledTextBar > span {
  color: var(--text-30);
}

.input {
  margin-top: 8px;
}
