@keyframes pulse {
  0% {
    background-color: var(--skeleton-10);
  }

  50% {
    opacity: .4;
    background-color: var(--skeleton-20);
  }

  100% {
    background-color: var(--skeleton-10);
  }
}

.skeletonWrapper {
  margin: 0;
  padding: 0;

  line-height: 1;

  display: flex;
}

.skeleton {
  display: inline-block;

  width: 100%; // default width
  height: 16px; // default height
  line-height: 1;

  border-radius: 4px;

  /* it's better to give margins via skeleton's className */

  /* margin-bottom: 4px; */
}

.pulseSkeleton {
  background-color: var(--skeleton-10);
  animation: pulse 1.5s ease-in-out .2s infinite;
}

// styles if skeleton is Array
.skeletonArray {
  display: flex;
  flex-direction: column;

  & span {
    flex: 1 1 auto;
    height: 20px;
  }
}

.skeletonWrapperDirection {
  flex-direction: row;
}
