.root {
  background-color: transparent;
  border: none;
  color: var(--text-30);
  padding: 0;

  &:hover {
    color: var(--text-20);
  }
}
