.youTubeContainer {
  max-width: 500px;
  margin: 0 auto;
  position: relative;
  padding-bottom: 56.3%;
  height: 0;

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.pipButtonHidden {
  visibility: hidden;
}
