@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

$max-container-width: 600px;
$min-container-width: 280px;
$header-height: 112px;

/* stylelint-disable-next-line unit-no-unknown */
$viewport-height-without-header: calc(100svh - $header-height);

html {
  overflow: hidden;
}

.root {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: var(--shape-10);
}

.header {
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
  height: $header-height;
  min-width: $min-container-width;
  background: var(--shape-10);
  box-shadow: var(--shadow-1);
}

.headerContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: $max-container-width;
  margin: 0 auto;
  padding: 16px;
  padding-top: 29px;
}

.title {
  @include font-style-h4();
  line-height: $line-height-subtitle;
  margin: 0;
  margin-top: 21px;
  width: 100%;
  font-weight: 400;
}

.scrollbar {
  width: 100%;
  height: $viewport-height-without-header;
  max-height: $viewport-height-without-header;

  & :global(.simplebar-content) {
    display: flex;
    justify-content: center;

    min-height: $viewport-height-without-header;
  }
}

.instruction {
  padding: 16px;
  padding-bottom: 24px;
  min-width: $min-container-width;
  max-width: $max-container-width;
}
