@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.container {
  padding-top: 4px;
}

.entry {
  display: grid;
  grid-template-columns: auto auto 1fr;
  margin-top: 4px;
  align-items: center;
  grid-row-gap: 8px;
}

.error {
  grid-column: 1 / -1;
}

.name {
  @include font-style-tiny;
  text-align: left;
  margin-left: 8px;
  color: var(--text-link);
  text-decoration: underline;

  &:hover,
  &:focus {
    opacity: .8;
  }
}

.amount {
  @include font-style-tiny;
  justify-self: end;
  flex-shrink: 0;
}
