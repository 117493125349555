@import 'src/design-system/styles/typography.mixins';

.passwordFormControl {
  width: 100%;
  margin: 10px 0 24px;
}

.newPassInputs {
  display: flex;
  justify-content: space-between;
  max-width: 500px;
  margin-bottom: 302px;

  & .passwordInput {
    max-width: 500px;
  }
}

.validationInfo {
  @include font-style-tiny;
  color: var(--text-30);
  display: inline-block;
  margin-top: 3px;
}
