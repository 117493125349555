@mixin primaryLight {
  --primary-20: #422d2e;
  --primary-60: #a6192e;
  --primary-90: #d4999b;
  --primary-110: #e7dbdb;
}

@mixin primaryDark {
  --primary-20: #eaf3bc;
  --primary-60: #d2c918;
  --primary-90: #7a7b38;
  --primary-110: #40412c;
}
