@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.root {
  display: flex;
  flex-direction: column;
  gap: 17px;
  width: 100%;
  padding: 12px 16px;
  border: 1px solid var(--shape-30);
  border-radius: 8px;
}

.badge {
  min-width: 137px;
}

.rate {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.title {
  @include font-style-subtitle($font-weight-regular);
  color: var(--text-10);
}

.description {
  @include font-style-small;
  color: var(--text-30);
}
