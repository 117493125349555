.background {
  fill: var(--shape-00);
}

.circle {
  fill: var(--shape-50);
}

.skeleton {
  stroke: var(--text-30);
}

.filledSkeleton {
  fill: var(--text-30);
}

.title {
  fill: var(--primary-60);
}

.star {
  fill: var(--controls-segmented-bg);
}

.roundStar {
  stroke: var(--controls-segmented-bg);
}

.plus {
  fill: var(--system-success);
}
