.root {
  display: flex;
  justify-content: space-between;
  gap: 2px;
  height: 8px;
  padding: 0;
  margin: 0;
  border-radius: 100px;
  overflow: hidden;
  list-style: none;
}

.section {
  height: 100%;
  flex-grow: 1;
  background-color: var(--shape-30);
}

.progress {
  height: 100%;
  background-color: var(--shape-20);
}

/* STATES */

.success {
  & .progress {
    background-color: var(--system-success);
  }

  & .section {
    background-color: var(--system-success-light);
  }
}

.note {
  & .progress {
    background-color: var(--text-link);
  }

  & .section {
    background-color: var(--text-link-light);
  }
}

.brand {
  & .progress {
    background-color: var(--primary-60);
  }

  & .section {
    background-color: var(--primary-110);
  }
}
