.root {
  align-self: stretch;
  position: relative;
  height: auto;

  & ul {
    width: 372px;
    padding: 0;
    right: -45px;

    & li:last-child {
      margin-top: 0;
    }
  }
}

.icon {
  position: relative;
}

.unreadCircle {
  &::before {
    content: '';
    position: absolute;
    height: 10px;
    width: 10px;
    top: 3px;
    right: 1px;
    background: var(--system-error);
    border: 1px solid var(--shape-10);
    border-radius: 50%;
  }
}
