@import 'src/design-system/styles/typography.mixins';

.periodLabel {
  @include font-style-tiny;
  color: var(--text-30);
  font-size: 12px;
}

.matchPeriodLabel {
  @include font-style-small;
  color: var(--text-10);
}

