@import 'src/design-system/styles/typography.mixins';

.list {
  @include list-styles-reset;
  margin: 1px 4px 4px;
  border-radius: 8px;
  overflow: hidden;
}

.item {
  box-shadow: 0 0 0 1px var(--shape-50);
}

.link {
  display: flex;
  padding: 6px 8px 6px 24px;
  @include font-style-tiny();
  color: var(--text-10);
  background-color: var(--shape-10);
}

.linkSelected,
.linkSelected:hover,
.linkSelected:focus-visible {
  color: var(--text-primary);
}
