@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.root {
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.noDataPic {
  padding: 14px;
  margin-bottom: 24px;
}

.title {
  @include font-style-subtitle($font-weight-bold);
  margin: 0;
}
