@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/layout-variables';

.headerWrapper {
  background-color: var(--shape-10);
  min-width: $screen-size-sm;
  box-shadow: inset 0 -1px 0 var(--shape-50);
}

.header {
  grid-column: 1/-1;
  display: flex;
  flex-wrap: wrap;
  max-width: $screen-size-sm;
  margin: 0 auto;
  min-height: 60px;
  padding: 0 15px;
  align-items: center;
}

.companyLogo {
  display: flex;
  align-self: center;
  position: relative;
  margin-right: 16px;
}

.tabs {
  align-self: stretch;
  flex-grow: 1;
  flex-shrink: 1;
  gap: 32px;
  border: none;

  & a {
    position: relative;
    display: flex;
    align-items: center;
    color: var(--text-10);
    margin: 0;

    &:hover,
    &:focus-visible {
      color: var(--text-20);
    }

    &::after {
      position: absolute;
      height: 4px;
      border-radius: 2px;
      bottom: 2px;
    }

    &:hover::after,
    &:focus-visible::after {
      background-color: var(--primary-60);
      opacity: .8;
    }
  }

  & .label {
    margin-top: 4px;
  }

  & [data-active='true']::after {
    background-color: var(--primary-60);
  }
}

.userInteractsWrapper {
  display: flex;
  align-items: center;
  gap: 24px;
}

.userInteractsContainer {
  display: flex;
  align-self: stretch;
  gap: 12px;
}

.btn {
  display: flex;
  align-items: center;
  padding: 0;
  color: var(--text-10);
  border: none;
  background: transparent;

  &:focus,
  &:hover,
  &:focus-visible {
    color: var(--text-20);
  }
}

.mobileAppIcons {
  margin-right: 60px;
}
