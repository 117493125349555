@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.container {
  position: relative;

  display: flex;
  align-items: center;
}

.label {
  transform: translateX(0);
  transition: transform linear .2s;
}

.labelWithEntries {
  transform: translateX(-12px);
}

.entries {
  @include font-style-tiny($font-weight-bold);

  position: absolute;
  right: -12px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;
  margin-left: 4px;
  border-radius: 50%;

  color: var(--text-100);
  background: var(--primary-60);
  opacity: 0;
}

.visibleEntries {
  opacity: 1;
  transition: opacity;
  transition-delay: .2s;
}

