@import 'src/design-system/styles/typography.variables';
@import 'src/design-system/styles/typography.mixins';

.price {
  @include font-style-small($font-weight-bold);
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  height: 18px;
  padding: 0 2px;
  border-radius: 2px;

  color: var(--text-10);
}

.increased {
  background-color: var(--system-success);
  color: var(--static-white);
}

.decreased {
  background-color: var(--system-error);
  color: var(--static-white);
}
