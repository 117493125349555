.root {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: var(--text-40);
  border-radius: 8px;
}

.positionStart {
  align-items: flex-start;
}

.img {
  max-height: 240px;
  min-height: 116px;
  height: 40vw;
  background: transparent;
}
