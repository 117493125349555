@import 'src/design-system/styles/typography.mixins';

.root {
  @include font-style-small;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 12px;
  border-radius: 8px;
  color: var(--text-30);
  background-color: var(--shape-20);
}

/* STATES */

.warning {
  color: var(--system-warning);
  background-color: var(--system-warning-light);
}

.error {
  color: var(--system-error);
  background-color: var(--system-error-light);
}

.success {
  color: var(--system-success);
  background-color: var(--system-success-light);
}

.note {
  color: var(--text-link);
  background-color: var(--text-link-light);
}

.brand {
  color: var(--text-primary);
  background-color: var(--primary-110);
}
