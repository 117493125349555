@import 'src/design-system/styles/layout-variables';

:global(.simplebar-track) {
  border-radius: 3px;
  z-index: 100;
}

:global(.simplebar-content::before) {
  content: none;
}

:global(.simplebar-content-wrapper) {
  &::-webkit-scrollbar {
    display: none;
  }
}

:global(.simplebar-track.simplebar-vertical .simplebar-scrollbar::before) {
  border-radius: 10px;
  width: 6px;
  background-color: var(--text-30);
  overflow-x: hidden;
}

.modulesScrollbarWrapper {
  & :global(.simplebar-wrapper) {
    max-height: calc(#{$height-without-header});
  }
}

:global(.simplebar-track.simplebar-horizontal) {
  display: none;
}

.scrollbarWrapper {
  height: 100%;
}
