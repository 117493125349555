@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.text {
  @include font-style-tiny;
  display: grid;
  grid-template-columns: auto auto 1fr;
  justify-items: end;
  align-items: center;
  margin-top: 12px;

  color: var(--text-10);

  &::before {
    content: '!';

    @include font-style-tiny($font-weight-bold);
    margin-right: 4px;
    color: var(--system-error);
  }
}

.button {
  @include font-style-tiny;
  padding: 4px 8px;
  min-width: 0;
}
