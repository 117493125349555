@import 'src/design-system/styles/typography.mixins';

.selectedPaymentOption {
  @include font-style-small;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 5px 8px;

  color: var(--text-10);
  background-color: var(--shape-50);
  border: 1px solid var(--shape-20);
  border-radius: 4px;
}

.removeSelectedPaymentOption {
  display: flex;
  border: none;
  background: none;
  padding: 6px;

  &:hover,
  &:focus {
    & path {
      opacity: .8;
    }
  }

  &[disabled] {
    & path {
      fill: var(--text-40);
    }
  }
}

.amountSpace {
  margin-top: 8px;
}
