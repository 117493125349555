.root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 10px 0;
}

@keyframes spinner-animation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.indicator {
  animation: spinner-animation .75s ease-out infinite;
}

.circle {
  stroke: var(--primary-90);
}

.circuit {
  stroke: var(--primary-60);
}

.white .circle {
  stroke: var(--static-white);
}

.white .circuit {
  stroke: var(--static-white);
}
