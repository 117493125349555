@import 'src/design-system/styles/typography.mixins';

.root {
  padding: 16px 24px;
}

.emptyStateTitle {
  margin-top: 5px;
  margin-bottom: 0;
}

.emptyStateCaption {
  @include font-style-tiny;
  color: var(--text-20);
}
