@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.label {
  display: flex;
  align-items: center;
  margin-bottom: 0;

  cursor: pointer;

  &:focus,
  &:hover {
    & .title {
      opacity: .8;
    }
  }
}

.title {
  @include font-style-small;
  color: var(--text-10);
  margin-left: 4px;
}

.disabled {
  pointer-events: none;

  & .title {
    color: var(--text-30);
  }
}
