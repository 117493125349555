.control {
  min-height: 0;

  & label {
    display: grid;
    grid-template-columns: 1fr auto;
  }
}

.field {
  min-width: 156px;
  max-width: none;
}

.button {
  min-width: 116px;
  white-space: nowrap;
  margin-left: 8px;
}

.clearFieldHelperSize {
  padding-bottom: 0;
}

.icon {
  color: var(--system-success) !important;
}
