@import 'src/design-system/styles/typography.mixins';

.root {
  @include list-styles-reset;
  display: flex;
  gap: 12px;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  color: var(--text-10);

  &:hover {
    color: var(--text-10);
    opacity: .8;
  }
}
