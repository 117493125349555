@import 'src/design-system/styles/typography.mixins';

.root {
  @include list-styles-reset;
  @include font-style-small;
  display: flex;
  flex-direction: column;
  gap: 32px;
  counter-reset: section;

  & li {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.itemTitle {
  position: relative;
  padding-left: 16px;

  &::before {
    counter-increment: section;
    position: absolute;
    left: 0;
    content: counter(section) '. ';
  }
}
