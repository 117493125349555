@import 'src/design-system/styles/typography.variables.scss';

.header {
  font-size: $font-size-subtitle;
  font-weight: $font-weight-bold;
  line-height: 24px;

  color: var(--text-10);
  padding-bottom: 16px;
}
