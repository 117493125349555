.iconSuccess {
  margin: auto 0;
  animation: success-icon .32s cubic-bezier(.42, 0, 1, 1);
  color: var(--static-white);
}

.savePersonalDataBtn {
  min-width: 111px;
  display: flex;
  justify-content: center;
}

@keyframes success-icon {
  0% {
    transform: scale(4);
    opacity: 0;
  }

  40% {
    opacity: 1;
  }

  100% {
    transform: scale(1);
  }
}
