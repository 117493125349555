@mixin shapeLight {
  --shape-00: #f2f2f2;
  --shape-10: #fff;
  --shape-20: #ededed;
  --shape-30: #ebebeb;
  --shape-40: #e8e8e8;
  --shape-50: #e3e3e3;
}

@mixin shapeDark {
  --shape-00: #121212;
  --shape-10: #1e1e1e;
  --shape-20: #232323;
  --shape-30: #252525;
  --shape-40: #272727;
  --shape-50: #2c2c2c;
}
