@import 'src/styles/animation-variables';

.container {
  position: relative;

  margin-bottom: 0;
  cursor: pointer;
}

.largeContainer {
  min-width: 40px;
  height: 24px;
}

.mediumContainer {
  min-width: 34px;
  height: 20px;
}

.smallContainer {
  min-width: 28px;
  height: 16px;
}

.input {
  position: absolute;
  top: 0;
  left: 0;

  opacity: 0;
  z-index: -1;

  &:focus ~ .button {
    border-color: var(--text-link);
  }
}

.button {
  position: absolute;
  top: 0;
  left: 0;

  background: var(--toggle-bg);
  border: 1px solid transparent;
  border-radius: 100px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;

    background: var(--toggle-bg);
    border-radius: 100px;

    transform: translateX(0);
    transition: $reactiveTransitionDuration ease transform;
  }
}

.largeButton {
  width: 40px;
  height: 24px;

  &::before {
    width: 22px;
    height: 22px;
    border: 6px solid var(--static-white);
  }
}

.mediumButton {
  width: 34px;
  height: 20px;

  &::before {
    width: 18px;
    height: 18px;
    border: 5px solid var(--static-white);
  }
}

.smallButton {
  width: 28px;
  height: 16px;

  &::before {
    width: 14px;
    height: 14px;
    border: 4px solid var(--static-white);
  }
}

.active {
  & > .button {
    background: var(--system-success);

    &::before {
      background: var(--system-success);
    }
  }

  & > .largeButton {
    &::before {
      transform: translateX(100%) translateX(-6px);
    }
  }

  & > .mediumButton {
    &::before {
      transform: translateX(100%) translateX(-4px);
    }
  }

  & > .smallButton {
    &::before {
      transform: translateX(100%) translateX(-2px);
    }
  }
}

.disabled {
  &.active {
    opacity: .5;

    & .button {
      &::before {
        border-color: var(--static-white);
      }
    }
  }

  cursor: no-drop;

  & > .button {
    pointer-events: none;

    &::before {
      border-color: var(--shape-50);
    }
  }
}
