@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables.scss';

.titleWrapper {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.title {
  @include font-style-subtitle($font-weight-bold);
  margin: 0 6px 0 0;
  color: var(--text-20);
}

.tooltip {
  width: 328px;
}

.titleIcon {
  color: var(--text-20);
}

.list {
  padding: 8px;
  margin: 0 0 24px 0;
  list-style: none;

  display: grid;
  grid-gap: 8px 16px;
  grid-template-columns: repeat(auto-fill, minmax(254px, 1fr));
  background: var(--shape-10);
  border-radius: 8px;
}

.noUserFullNameNotice {
  @include font-style-small;
  color: var(--system-error);
  background: var(--shape-10);
  border: 1px solid var(--system-error);
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  margin-bottom: 6px;
}
