@import 'src/design-system/styles/typography.mixins';

.form {
  margin-bottom: 296px;
}

.fieldInfo {
  @include font-style-tiny;

  display: inline-block;
}

.error {
  color: var(--system-error);
}
