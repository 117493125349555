.content {
  display: flex;
  justify-content: center;
  padding: 24px;
  padding-top: 0;
}

.code {
  padding: 25px;
  background: var(--static-white);
  border-radius: 10px;
}

