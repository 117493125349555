@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.singleEntry {
  width: 100%;
}

.selectedEntry {
  opacity: .5;
}

.entryName {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.selected {
  @include font-style-small($font-weight-bold);
  margin-left: 4px;
  color: var(--text-10);
}

.stakeNameContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  width: 100%;
}

.maxWinning {
  display: inline-flex;
  align-items: center;
  height: 20px;
  background-color: var(--shape-20);
}

.amount {
  @include font-style-tiny($font-weight-medium);

  margin-left: 4px;
}
