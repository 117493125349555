@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.lineSlotContainer {
  padding: 20px 0;
  border-bottom: 1px solid var(--shape-50);
}

.slotName {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 32px 8px;
  @include font-style-tiny($font-weight-bold);
  color: var(--text-10);
}

.slotStakes {
  display: flex;
  flex-wrap: wrap;
  padding: 0 32px 16px;
  gap: 8px;

  &:last-child {
    padding-bottom: 0;
  }
}

.matchesSlotName {
  padding: 0 16px 8px;
}

.outrightMatch {
  padding-top: 12px;
}

.disabledIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  pointer-events: auto;
  cursor: pointer;
  background-color: var(--text-40);
  color: var(--static-white);

  &:hover {
    background-color: var(--system-error);
  }
}
