@import 'src/design-system/styles/typography.variables';
@import 'src/design-system/styles/typography.mixins';

.container {
  margin-top: 6px;
  background-color: var(--shape-10);
  border: 1px solid var(--shape-50);
  border-radius: 8px;
}

.disabledEntry {
  pointer-events: none;
  opacity: .7;
}

.notConflictedEntry {
  pointer-events: none;
  opacity: .5;
}

.header {
  @include font-style-small($font-weight-medium);
  line-height: 14px;

  display: grid;
  grid-template-columns: auto 1fr auto;

  height: 30px;
  padding: 8px;
  color: var(--text-10);
  border-bottom: 1px solid var(--shape-50);
}

.icon {
  margin-right: 4px;
}

.bets {
  padding: 8px 8px 6px;

  & > div {
    margin-top: 17px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 14px;
    }
  }

  &::after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background-color: var(--shape-50);
  }
}

.matchName {
  padding: 0 8px;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 2px 8px 8px;
}

.entryErrorMessage {
  margin-top: 4px;
}

.priceDiffContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
