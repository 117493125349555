@import './css-variables';
@import './typography.mixins';
@import './link.mixins';

/* html elements' defaults */
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
}

html,
body {
  margin: 0;
  height: 100%;
}

body,
table {
  font-family: $font-family-default;
  @include font-style-default;
}

body {
  background-color: var(--shape-00);
  color: var(--text-10);
}

h1 {
  @include font-style-h1;
}

h2 {
  @include font-style-h2;
}

h3 {
  @include font-style-h3;
}

h4 {
  @include font-style-h4;
}

h5 {
  @include font-style-subtitle;
}

a {
  @include link-style;
}


/* html class helpers */

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

td.ellipsis {
  position: relative;

  white-space: initial;
  overflow: initial;
  text-overflow: initial;

  &::before {
    content: '&nbsp;';
    visibility: hidden;
  }

  & > span,
  & > a {
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

body #fc_frame.fc-widget-normal {
  z-index: 1000;
}

// From bootstrap. We should to decrease useless.

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

input,
button,
select,
textarea {
  font: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
}

img {
  vertical-align: middle;
}

a {
  text-decoration: none;
}

p {
  margin: 0 0 10px;
}

button,
input[type='reset'],
input[type='submit'] {
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
