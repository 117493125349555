@import 'src/design-system/styles/typography.mixins';
@import 'src/styles/animation-variables';

.childrenWrapper {
  display: inline-flex;
  padding: 0;
  border: none;
  background: transparent;

  &:hover {
    opacity: .8;
  }
}

.popper {
  @include font-style-small;

  position: absolute;
  display: none;

  min-width: 256px;
  max-width: 508px;

  background: transparent;
  box-shadow: var(--shadow-2);
  border-radius: 2px;

  opacity: 0;
  z-index: 1010;

  &[data-show] {
    display: flex;
  }

  &[data-popper-placement^='top'] .arrow {
    bottom: -6px;
  }

  &[data-popper-placement^='bottom'] .arrow {
    top: -6px;
  }

  &[data-popper-placement^='left'] .arrow {
    right: -6px;
  }

  &[data-popper-placement^='right'] .arrow {
    left: -6px;
  }
}

.hint {
  font-size: $font-size-tiny;
  line-height: $line-height-extra-small;

  min-width: 30px;
  max-width: 280px;

  box-shadow: var(--shadow-2);

  &::before {
    content: '';
    background-color: var(--shape-50);
    position: absolute;
    border-radius: 2px;
  }

  & .arrow {
    width: 14px;
    height: 14px;
  }

  & .arrow::before {
    width: 100%;
    height: 100%;
  }

  &[data-popper-placement^='top'] {
    & .arrow {
      bottom: -4px;
    }

    &::before {
      width: 100%;
      height: 10px;
    }
  }

  &[data-popper-placement^='bottom'] {
    & .arrow {
      top: -4px;
    }

    &::before {
      width: 100%;
      height: 10px;
    }
  }

  &[data-popper-placement^='left'] {
    & .arrow {
      right: -4px;
    }

    &::before {
      width: 10px;
      height: 100%;
    }
  }

  &[data-popper-placement^='right'] {
    & .arrow {
      left: -4px;
    }

    &::before {
      width: 10px;
      height: 100%;
    }
  }
}

.popperContent {
  position: relative;

  width: 100%;
  height: 100%;
  padding: 18px 24px 18px 16px;

  background: var(--shape-10);
  color: var(--text-10);
  border-radius: 2px;

  overflow: auto;
  z-index: 1000;
}

.markdown {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.hintContent {
  padding: 4px 8px 6px;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow,
.arrow::before {
  position: absolute;
  width: 24px;
  height: 24px;
  z-index: 990;
}

.arrow::before {
  content: '';
  border-radius: 2px;
  background: var(--shape-10);
  transform: rotate(45deg);

  box-shadow: var(--shadow-2);
}

.closeButton {
  position: absolute;
  top: 4px;
  right: 4px;

  display: flex;

  width: 20px;
  height: 20px;
  padding: 5px;

  background: transparent;
  border: none;

  cursor: pointer;
  outline: none;

  & path {
    fill: var(--text-10);
    opacity: .5;
    transition: $reactiveTransitionDuration $smoothTransitionTiming fill;
  }

  &:focus,
  &:hover {
    & path {
      fill: var(--text-10);
      opacity: .4;
    }
  }
}
