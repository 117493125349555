@import 'src/design-system/styles/typography.mixins';

.cleanupButton {
  border: none;
  background: none;
  padding: 0;

  & path {
    fill: var(--text-20);
  }

  &:focus,
  &:hover {
    & path {
      opacity: .8;
    }
  }

  &:disabled {
    visibility: hidden;
  }
}

.container {
  @include font-style-small;

  position: absolute;
  top: calc(100% + 1px); // 1px - preferences-border
  left: 0;

  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto auto;
  width: 100%;
  height: 42px;
  padding: 0 6px 0 8px;

  background: var(--shape-10);
  color: var(--text-10);
  box-shadow: var(--shadow-1);
  z-index: 105;
}

.button {
  min-width: 0;
  padding: 4px 8px;

  &:focus {
    padding: 3px 7px;
  }

  &:last-child {
    margin-left: 8px;
  }
}
