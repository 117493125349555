.icon {
  position: relative;
}

.unreadCircle {
  &::before {
    content: '';
    position: absolute;
    height: 10px;
    width: 10px;
    background: var(--system-error);
    border: 1px solid var(--shape-10);
    border-radius: 50%;
  }
}
