@import 'src/design-system/styles/typography.mixins';

.root {
  @include font-style-tiny;
  color: var(--text-30);
  margin: 0 0 0 6px;
  position: absolute;
  top: 0;
  left: 12px;
}

.animationRun {
  animation: favorite-success-action .32s ease-in;
}

.animationClose {
  animation: favorite-close-action .32s ease-out;
  animation-fill-mode: forwards;
}

@keyframes favorite-success-action {
  0% {
    transform: translateX(-30px);
    opacity: 0;
  }

  50% {
    transform: translateX(-5px);
    opacity: 0;
  }

  70% {
    transform: translateX(-2px);
    opacity: .5;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes favorite-close-action {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  20% {
    transform: translateX(-2px);
    opacity: .5;
  }

  50% {
    transform: translateX(-10px);
    opacity: 0;
  }

  100% {
    transform: translateX(-30px);
    opacity: 0;
    display: none;
  }
}
