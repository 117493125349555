@mixin shadowLight {
  --shadow-1: 0 2px 3px rgba(0, 0, 0, .08), 0 1px 4px -2px rgba(0, 0, 0, .06);
  --shadow-2: 0 1px 2px rgba(0, 0, 0, .2), 0 2px 6px 1px rgba(0, 0, 0, .1);
  --shadow-3: 0 4px 6px 4px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .2);
  --shadow-4: 0 8px 10px 5px rgba(0, 0, 0, .1), 0 4px 5px -1px rgba(0, 0, 0, .2);
}

@mixin shadowDark {
  --shadow-1: 0 1px 2px rgba(0, 0, 0, .3), 0 1px 3px 1px rgba(0, 0, 0, .15);
  --shadow-2: 0 2px 3px rgba(0, 0, 0, .3), 0 2px 4px 3px rgba(0, 0, 0, .15);
  --shadow-3: 0 4px 6px 3px rgba(0, 0, 0, .15), 0 2px 3px rgba(0, 0, 0, .3);
  --shadow-4: 0 8px 12px 6px rgba(0, 0, 0, .15), 0 5px 8px rgba(0, 0, 0, .3);
}
