@import 'src/design-system/styles/typography.mixins';

.list {
  @include list-styles-reset;
}

.item {
  border-radius: 8px;
  overflow: hidden;
}

.link {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 8px;
  @include font-style-small($font-weight-bold);
  color: var(--text-10);
}

.icon {
  flex-shrink: 0;
  width: 22px;
  height: 22px;
  border-radius: 4px;
  overflow: hidden;
}

.iconWithBackground {
  background-color: var(--shape-40);
}

.matchesCount {
  margin-left: auto;
  margin-top: 2px;
  color: var(--text-30);
}

.link:hover,
.link:focus-visible {
  color: var(--text-primary);
  background-color: var(--primary-110);

  & .matchesCount {
    color: var(--text-primary);
  }
}

.itemSelected {
  color: var(--text-primary);
  background: var(--shape-00);
}

.itemSelected .link {
  color: var(--text-primary);

  & .matchesCount {
    color: var(--text-primary);
  }
}

.label,
.caption {
  @include one-line-ellipsis;
  margin-top: 2px;
}
