@import 'src/design-system/styles/typography.variables';
@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/layout-variables';

$filter-height: 44px;

.scrollbars {
  max-height: calc(70vh - #{$main-search-height} - #{$filter-height});
}

.smallLength,
.notFound {
  @include font-style-subtitle($font-weight-regular);
  color: var(--text-10);
}

.loader,
.error,
.notFound,
.smallLength {
  display: flex;
  justify-content: center;
  padding-top: 48px;
  padding-bottom: 48px;
  position: relative;
}

.error {
  color: var(--system-error);
}
