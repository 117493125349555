@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }

  40% {
    transform: translateX(-2px);
  }

  60%,
  80% {
    transform: translateX(-1px);
  }

  10% {
    transform: translateX(6px);
  }

  50% {
    transform: translateX(3px);
  }

  70%,
  90% {
    transform: translateX(1px);
  }
}

.shake {
  animation: shake 700ms;
}
