@mixin buttonLight {
  --button-cta-default: #a6192e;
  --button-cta-hover: #b84652;
  --button-cta-pressed: #c25f68;
  --button-primary-default: #e7dbdb;
  --button-primary-hover: #dbb9b9;
  --button-primary-pressed: #d4999b;
}

@mixin buttonDark {
  --button-cta-default: #d2c918;
  --button-cta-hover: #b5b129;
  --button-cta-pressed: #989734;
  --button-primary-default: #40412c;
  --button-primary-hover: #5d5e35;
  --button-primary-pressed: #7a7b38;
}
