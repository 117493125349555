@import 'src/design-system/styles/typography.variables';
@import 'src/design-system/styles/typography.mixins';

.matchScoreboardCell {
  @include font-style-tiny;
  color: var(--text-10);
  text-align: right;
  padding: 0 3px;
}

.matchMainScore {
  font-weight: $font-weight-bold;
  padding: 0 4px;
}

.penaltyCardWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1px 3px;
}

.second {
  justify-content: flex-end;
  height: 34px;
}

.soccerCard {
  @include font-style-extra-small;
  color: var(--text-10);

  position: relative;
  padding: 0 3px;
  z-index: 1;

  text-align: start;

  & span {
    @include visuallyHidden;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
  }

  &::before {
    top: -2px;
    left: -2px;

    width: 6px;
    height: 8px;
    border-radius: 1px;
  }

  &::after {
    left: 2px;
    top: 1px;

    width: 5px;
    height: 10px;
    border-radius: 5px;
    background: var(--shape-10);
  }

  &.yellow::before {
    background: var(--system-warning);
  }

  &.red::before {
    background: var(--system-error);
  }
}

.firstPeriodScore {
  font-weight: $font-weight-bold;
}

.currentPeriodScore {
  margin-left: 3px;
  border: 1px solid var(--shape-50);

  &:hover {
    background-color: var(--shape-40);
    opacity: 1;
  }

  & .active .matchScoreboardCell {
    color: var(--system-error);
    padding: 0 4px 0 18px;
  }

  &:not(.setWrapper) .matchScoreboardCell {
    color: var(--system-error);
  }
}

.mainScoreWrapper {
  @include list-styles-reset;
}

.scoreDivider {
  position: relative;
  margin-left: 10px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -4px;
    width: 1px;
    height: 100%;
    background-color: var(--shape-50);
  }
}

.scoreboardCellsWrapper {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  cursor: pointer;

  &.gameWrapper {
    flex-direction: row;
  }

  &:hover:not(.currentPeriodScore) {
    opacity: .8;
  }
}

.noTitleContent {
  cursor: default;
}

.backgroundAnimation {
  animation: 1s, background 1.5s;
  border-radius: 4px;

  & .matchScoreboardCell {
    animation: color 1.5s;
  }
}


@keyframes background {
  0%,
  50% {
    background: var(--grid-match-score-change-bg);
  }

  100% {
    background-color: transparent;
    color: var(--text-10);
  }
}

@keyframes color {
  0%,
  50% {
    color: var(--system-error);
  }

  100% {
    color: var(--text-10);
  }
}
