@import 'src/design-system/styles/typography.mixins';

.headerContent {
  display: flex;
}

.title {
  @include font-style-h4($font-weight-regular);
  width: 375px;
}
