@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.dropdown {
  & :global(.dropdown__loading-indicator) {
    display: none;
  }

  & :global(.dropdown__control) {
    width: 100%;
    border: 1px solid var(--shape-40);

    &:hover {
      border-color: var(--text-40);
    }
  }
}

.loading {
  & :global(.dropdown__dropdown-indicator) {
    & svg {
      fill: transparent;
    }
  }
}

.portal {
  & :global(.dropdown__menu) {
    margin: 2px 0;

    border: 1px solid var(--shape-50);
    background-color: var(--shape-10);
    border-radius: 4px;
    box-shadow: var(--shadow-4);
    overflow: hidden;
    z-index: 105;
  }

  & :global(.dropdown__option) {
    @include font-style-tiny;

    display: flex;
    align-items: center;

    height: auto;
    padding: 6px 8px;

    color: var(--text-30);
    background-color: var(--shape-10);
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: var(--shape-50);
    }
  }

  & :global(.dropdown__option--is-selected),
  :global(.dropdown__option--is-disabled) {
    cursor: not-allowed;

    &:hover,
    &:focus {
      background-color: var(--shape-10);
    }
  }

  & :global(.dropdown__menu-notice--no-options) {
    padding: 24px 0;
    color: var(--text-30);
  }
}

.menuHeader {
  padding: 12px 8px 0;
  margin-bottom: 0;
  background-color: var(--shape-10);
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 125px;
}
