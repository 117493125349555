@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

@keyframes increase {
  0%,
  66% {
    background-color: var(--grid-increase-bg);
  }

  100% {
    background-color: var(--grid-bet-bg);
  }
}

@keyframes decrease {
  0%,
  66% {
    background-color: var(--grid-decrease-bg);
  }

  100% {
    background-color: var(--grid-bet-bg);
  }
}

@keyframes increaseMOD {
  0%,
  66% {
    background-color: var(--grid-static-increase-bg);
  }

  100% {
    background-color: var(--grid-static-bet-bg);
  }
}

@keyframes decreaseMOD {
  0%,
  66% {
    background-color: var(--grid-static-decrease-bg);
  }

  100% {
    background-color: var(--grid-static-bet-bg);
  }
}

@mixin disabledTextSelection {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.bet {
  @include font-style-tiny;
  text-align: left;
  font-size: 11px;

  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 6px 8px;
  border: none;

  background-color: var(--grid-bet-bg);

  &:hover {
    background-color: var(--shape-50);

    & .betLabel {
      color: var(--text-10);
    }
  }

  &:global(.conflict) {
    cursor: default;

    & .betLabel,
    :global(.price) {
      color: var(--text-40);
    }

    &:not(:global(.active)):hover {
      background-color: var(--grid-bet-bg);
    }
  }

  &:global(.active) {
    background-color: var(--static-secondary);

    & .betLabel,
    :global(.price) {
      color: var(--static-black);
    }
  }

  &.increased {
    animation: increase 3s;

    & .betLabel {
      color: var(--text-30);
    }

    & :global(.price) {
      color: var(--system-success);
    }
  }

  &.decreased {
    animation: decrease 3s;

    & .betLabel {
      color: var(--text-30);
    }

    & :global(.price) {
      color: var(--system-error);
    }
  }

  &.increasedMatchOfDay {
    animation: increaseMOD 3s;

    & .betLabel {
      color: var(--text-30);
    }

    & :global(.price) {
      color: var(--system-success);
    }
  }

  &.decreasedMatchOfDay {
    animation: decreaseMOD 3s;

    & .betLabel {
      color: var(--text-30);
    }

    & :global(.price) {
      color: var(--system-error);
    }
  }

  &:global(.disabled) {
    cursor: default;
    pointer-events: none;

    @include disabledTextSelection;

    & .betLabel,
    :global(.price) {
      color: var(--text-40);
    }

    &:hover {
      background-color: var(--grid-bet-bg);
    }

    &.increased,
    &.decreased {
      animation: none;
    }
  }
}

.betLabel {
  color: var(--text-30);
  width: 100%;

  @include one-line-ellipsis;
}
