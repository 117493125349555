@import 'src/design-system/styles/layout-variables';
@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.container {
  position: absolute;
  z-index: 1;
  right: 0;
  grid-column: 3 / 4;

  display: flex;
  flex-direction: column;

  height: 100%;
  width: $sidebar-width;

  background-color: var(--shape-10);
  overflow: hidden;

  box-shadow: 1px 0 0 var(--shape-50), -1px 0 0 var(--shape-50);
}

.tabsContainer {
  height: 100%;
  overflow: hidden;
}

.menuHeader {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;

  min-height: 41px;
  border-top: 1px solid var(--shape-50);

  & > button {
    @include font-style-default($font-weight-bold);

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 1px 5px;
    margin: 0;

    &::after {
      content: none;
    }

    &[data-active='true'] {
      color: var(--text-10);
      background: var(--shape-10);
    }

    &[data-active='false'] {
      color: var(--text-20);
      background: var(--shape-20);
    }

    &:hover,
    &:focus-visible {
      background: var(--shape-50);
    }
  }
}
