.inputContainer {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto auto;

  min-height: 32px;
  border-radius: 4px;

  &:hover {
    & .input,
    .units,
    .select {
      border-color: var(--text-40);
    }
  }

  &.focus {
    & .select {
      border-color: var(--text-link);
    }
  }

  &.error {
    & .input,
    & .units,
    & .select {
      border-color: var(--system-error) !important;
    }
  }
}

.select {
  border: 1px solid var(--shape-40);
  border-left: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  & :global(.dropdown__control) {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.disabled {
  pointer-events: none;

  & .input,
  & .input > input,
  & .units {
    background: var(--shape-20);
  }

  & .units path {
    fill: var(--text-40);
  }

  & .input,
  & .input > input {
    color: var(--text-40);

    &::placeholder {
      color: var(--text-40);
    }
  }

  & .select {
    pointer-events: none;
  }
}
