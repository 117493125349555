@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.label {
  @include font-style-tiny($font-weight-bold);
  color: var(--text-20);
  text-transform: uppercase;
}

.value {
  @include font-style-default;
  color: var(--text-10);
  margin: 0;
}
