@import 'src/design-system/styles/typography.mixins';

.root {
  display: flex;
  flex-direction: column;
}

.link {
  @include font-style-default;
}
