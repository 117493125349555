@import 'src/design-system/styles/typography.variables';
@import 'src/design-system/styles/typography.mixins';

.badge {
  @include font-style-tiny($font-weight-bold);
  line-height: $line-height-extra-tiny;
  margin-right: 3px;
  color: var(--text-20);
  background-color: var(--shape-50);
  border-radius: 3px;
}

.small {
  font-size: 10px;
  padding: 0 2px;
}

.medium {
  padding: 3px 4px;
}

.large {
  padding: 4px 6px;
}

.primary {
  color: var(--static-white);
  background-color: var(--system-error);
}

.secondary {
  color: var(--static-white);
  background-color: var(--text-link);
}
