.warningIcon {
  fill: var(--system-warning);
}

.border {
  fill: var(--shape-50);
}

.display {
  fill: var(--static-gray-20);
}

.stand {
  fill: var(--text-40);
}
