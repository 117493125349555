@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: $font-family-default;
}

.titleDefault {
  @include font-style-subtitle($font-weight: $font-weight-regular);
  font-family: inherit;

  margin: 0;
}

.titleLarge {
  @include font-style-h4($font-weight: $font-weight-regular);
  font-family: inherit;

  color: var(--text-10);

  margin: 0 0 4px 0;
}

.titleBold {
  font-weight: bold;
}

.content {
  display: flex;
  flex-direction: column;
  position: relative;

  padding: 24px;
  box-shadow: var(--shadow-2);
  background-color: var(--shape-10);
  border-radius: 8px;

  font-family: inherit;
}

.contentNoPadding {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
}
