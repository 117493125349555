@import 'src/design-system/styles/typography.mixins';

.footerCancelButton {
  min-width: 88px;
}

.contentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 422px;
}

.offerContainer {
  @include font-style-default;

  padding-bottom: 8px;
  color: var(--text-10);
  max-height: 430px;
}

.offerLink {
  @include font-style-small;
  cursor: pointer;
}

.offerModal {
  background-color: transparent;
}

.noAlignment {
  display: block;
}
