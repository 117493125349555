@import './layout-variables';

@mixin contentWithPaddings {
  width: #{$content-width};
  padding:
    0
    calc((100% - #{$content-width} - #{$left-menu-width} + #{$sidebar-width}) / 2)
    0
    calc((100% - #{$content-width} - #{$sidebar-width} + #{$left-menu-width}) / 2);
}
