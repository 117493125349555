@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/layout-variables';
@import 'src/modules/Betting/grid.variables.scss';

.gridHeader {
  background: var(--shape-00);

  position: sticky;
  top: 0;
  left: 0;

  display: grid;
  grid-template-columns: auto 1fr;
  align-items: end;

  padding: $grid-header-indent-top $content-indent-right $grid-header-indent-bottom $content-indent-left;

  z-index: 20;
}

.name {
  @include font-style-subtitle($font-weight-bold);
  color: var(--text-10);

  margin: 0;

  & > span {
    padding-right: 8px;
  }

  &:hover,
  &:focus {
    color: var(--text-10);
  }

  &:hover {
    opacity: .8;
  }

  @include one-line-ellipsis;
}

.icon {
  color: var(--text-10);
}

.gridColumnsHeader {
  display: flex;
  justify-content: flex-end;
}
