.background {
  fill: var(--shape-10);
}

.overlay {
  fill: var(--shape-50);
}

.skeleton {
  stroke: var(--text-30);
}

.star {
  fill: var(--controls-segmented-bg);
}

.roundStar {
  stroke: var(--controls-segmented-bg);
}

.redStar {
  fill: var(--primary-60);
}

.redRoundStar {
  fill: var(--shape-10);
  stroke: var(--primary-60);
}
