.root {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: var(--text-30);
  padding: 0;
  position: relative;

  &:hover {
    color: var(--system-warning);
  }
}

.favoriteIconActive {
  color: var(--system-warning);

  &:hover {
    opacity: .8;
  }
}
